@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Vazirmatn:wght@100;200;300;400;500;600;700;800;900&display=swap");

// @import "./sass/icons.css";
@import "./sass/abstracts/mixins";
@import "./sass/abstracts/variables";
// @import "./antd/_custom_antd";

@import "./reset";
@import "./global";

// App.scss
@import "./sass/website/_footer.scss";
@import "./sass/website/_header.scss";
// auth
@import "/public/assets/common/styleSheets/sass/auth/_auth.scss";
// admin
// @import "/public/assets/common/styleSheets/sass/admin/_admin.scss";
@import "/public/assets/common/styleSheets/sass/admin/_sideBar.scss";
@import "/public/assets/common/styleSheets/sass/admin/_header.scss";
@import "/public/assets/common/styleSheets/sass/admin/_profile.scss";
// website
@import "/public/assets/common/styleSheets/sass/website/_website.scss";
@import "/public/assets/common/styleSheets/sass/profile/_profile.scss";
@import "/public/assets/common/styleSheets/sass/profile/package/steps/_review.scss";
// search
@import "/public/assets/common/styleSheets/sass/search/search.scss";
// profile/common
@import "/public/assets/common/styleSheets/sass/common/dialog/_dialog.scss";
@import "/public/assets/common/styleSheets/sass/common/loading/_loading.scss";
@import "/public/assets/common/styleSheets/sass/common/fields/_inputField.scss";
// profile/package
@import "/public/assets/common/styleSheets/sass/profile/package/_new.scss";
// @import "/public/assets/common/styleSheets/sass/profile/package/statistics.scss";
// popups
@import "/public/assets/common/styleSheets/sass/common/popup/_popup.scss";
@import "/public/assets/common/styleSheets/sass/common/utilities/EmptyState.scss";

@import "swiper/css";
@import "swiper/css/free-mode";
@import "swiper/css/navigation";
@import "swiper/css/thumbs";
@import "swiper/css/pagination";

html {
	font-size: 16px;
}
#__next {
	height: 100%;
}

.error-msg {
	position: absolute;
	font-size: 14px;
	color: #cc3300;
}
.data-flex-col {
	display: flex;
	flex-direction: column;
}

.form_container {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 20px;
	grid-auto-flow: row;
	@media (max-width: 768px) {
		grid-template-columns: repeat(1, 1fr);
	}
	.items {
		@media (max-width: 768px) {
			width: 100%;
		}
	}
	column-gap: 22px;
	row-gap: 2rem;
	margin-top: 30px;
	padding-bottom: 2rem;
	.custom_form_input {
		@include flex(center, space-between);
		gap: 25px;
		flex-wrap: nowrap;
		flex-basis: calc(50% - 11px);
		max-width: 470px;
		min-width: 250px;
		@media only screen and (max-width: 992px) {
			max-width: 100%;
			min-width: 100%;
		}
		.ant-row {
			width: 100%;
		}
		.container_phone_class {
			border: 0;
			.react-tel-input {
				height: 50px;
				background: #ffffff;
				border-radius: 8px;
				text-align: left;
				&:lang(ar) {
					text-align: right;
				}
				.form-control {
					height: 50px;
					background: #ffffff;
					color: #8d8d8d;
					text-align: left;
					border: 1px solid #dadde3;
					border-radius: 6px;
					width: 100%;
					&:lang(ar) {
						text-align: right;
						padding-left: 12px;
						padding-right: 48px;
					}
					&:focus {
						border: 1px solid #000000;
						color: #000000;
					}
				}
			}
			.select_class_phone,
			.input_class_phone {
				height: 100%;
				input {
					border-inline-start: 0;
				}
				& input,
				& span {
					@include font(16, 400, #8d8d8d);
					border: 0;
				}
				input {
					height: 100%;
					color: #8d8d8d;

					&:focus {
						border: 1px solid #000000;
						color: #000000;
					}
				}
				span {
					padding: 0;
				}
			}
		}

		&.change_password-container {
			// justify-content: flex-end;
			button {
				width: 169px;
				height: 52px;
			}
		}
		.custom-email_container {
			@include flex(center, space-between);
			position: relative;
			flex: 1;
			width: 100%;
			@media (min-width: 870px) {
				flex-wrap: nowrap;
			}
			.emil_verificaion {
				&:lang(ar) {
					margin-left: 0.5rem;
				}
				@include flex(center, space-between);
				flex-wrap: nowrap;
				gap: 5px;
				height: 25px;
				padding: 7px 20px;
				background: #ff851a33;
				border-radius: 17px;
				text-align: left;
				font: normal normal 400 14px/20px Poppins;
				color: #ff851c;
				position: absolute;
				// top: 35px;
				bottom: 8px;
				inset-inline-end: 10px;
				&.--verified {
					background: #57a72133;
					color: #57a721;
					.verificaiton_icon {
						display: flex;
						width: 16px;
						height: 16px;
					}
				}
			}
		}
		.ant-form-item-control-input-content {
			@extend .custom-email_container;
		}

		&.--editing {
			.input_class {
				gap: 20px;
			}
			.custom-email_container {
				width: 100%;
			}
		}
		.k-label {
			text-align: left;
			font: normal normal 500 14px/20px Poppins;
			color: #000000;
			margin: 0;
		}
		.container_phone {
			display: flex;
			flex-direction: column;
			gap: 6px;
			width: 100%;
			.container_phone_class {
				.select_class_phone {
					width: 25%;
					span {
						border: 0;
						&.k-dropdownlist {
							height: 100%;
							color: #8d8d8d;

							&:focus {
								border: 1px solid #000000;
								color: #000000;
							}
						}
					}
				}
			}
		}
	}
	.submit_button_container {
		display: flex;
		width: 100%;
		justify-content: flex-start;
		align-self: flex-end;
		.submit_button {
			width: 100%;
			height: 54px;
		}
	}
}
.form_footer {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
	align-items: end;
	// .ant-btn.ant-btn-link {
	// 	height: 53px;
	// }
}

.verify-warning-holder {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #eb2a14cc;
	color: #fff;
	min-height: 42px;
	font-size: 12px;
	font-weight: 500;
	padding: 10px;
	text-align: center;
	a {
		text-decoration: underline;
		color: #fff;
		margin: 0 3px;
	}
	&.verify__succed {
		background-color: #449d48 !important;
	}
	span {
		text-decoration: underline;
		display: inline-block;
		margin: 0 16px;
		cursor: pointer;
	}
	@media only screen and (max-width: 992px) {
		// flex-direction: column;
		flex-wrap: wrap;
		font-size: 11px;
	}
}

.react-tel-input .selected-flag {
	&:lang(ar) {
		padding-left: 0;
		padding-right: 8px;
	}
	.arrow {
		&:lang(ar) {
			right: 20px;
			left: right;
		}
	}
}

html {
	// overflow-y: scroll; // !Removed this to fix onScroll event issue
}

.error-page-content-holder {
	> span {
		background-color: #fff !important;
	}
	img {
		mix-blend-mode: multiply;
	}
}

.country-seats-holder {
	display: flex;
	align-items: center;
	gap: 20px;
	.total-seats-holder {
		display: flex;
		align-items: center;
	}
}
.partner_collapse {
	background-color: #fff;
	.ant-collapse-header {
		font-size: 18px;
		font-weight: 500;
	}
	.ant-collapse-content-box {
		font-size: 16px;
	}
}

.availableSeats_dialog {
	.ant-modal-body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 40px 50px;
		gap: 20px;
		@media (max-width: 425px) {
			padding: 20px 30px;
		}
		svg {
			@media (max-width: 425px) {
				width: 60px;
				height: 60px;
			}
		}
		h4 {
			font: normal normal 500 18px/25px Poppins;
			color: #000000;
		}
		p {
			font: normal normal normal 14px/24px Poppins;
			text-align: center;
			color: #000000;
		}
	}
}
