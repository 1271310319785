.loading_container {
    width: 100%;
    height: 100vh;
    position: relative
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
}