.auth-content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 30px;
    .AuthBox_form {
        box-shadow: 0px 6px 36px rgb(0 0 0 / 8%);
        max-width: 520px;
        min-width: 500px;
        min-height: 400px;
        background: #fff;
        border-radius: 16px;
        padding: 40px 50px;
        @media (max-width: 992px) {
            width: 90%;
            min-height: auto;
            padding: 20px;
            min-width: max-content;
            margin: 91px auto 20px;
        }
    }
    @media (max-width: 992px) {
        padding-top: 0;
        padding-bottom: 0;
    }
}
.AuthBox_Register {
    & .RegisterBox_Title {
        width: 100%;
        font-weight: 600;
        color: $vibrarnt-black;
        margin: 0px 0px 22px 0px;
        font-size: 28px;
        @media (max-width: 992px) {
            font-size: 22px;
        }
    }
    & .RegisterBox_SignUp {
        width: 100%;
        text-align: center;
        height: 17px;
        font-size: 12px;
        font-weight: 500;
        color: #838383;
        margin: 20px auto;
        a {
            font-size: 12px;
            font-weight: 500;
            margin-inline-start: 3px;
        }
    }
    & .RegisterBox_OtherSign {
        width: 100%;
        height: 10px;
        border-bottom: 1px solid #a7a7a7;
        text-align: center;
        margin-bottom: 32px;
        .RegisterBox_OtherSign1_Span {
            color: #2b2f33;
            font-size: 14px;
            font-weight: 600;
            background-color: $white_color;
            padding: 0 10px;
        }
    }
}

.rc-anchor-normal {
    width: 99%;
}
