.k-dialog {
    position: relative;
    min-width: 352px;
    max-width: 1200px;
    max-height: calc(100vh - 27px);
    @media (max-width: 1200px) {
        max-width: calc(100% - 20px);
    }
    @media (max-width: 700px) {
        max-width: calc(100% - 20px);
        min-width: initial;
    }
    background: $white_color;
    box-shadow: 0px 4px 16px #00000010;
    border-radius: 20px !important;
    .dialog_save {
        width: 100%;
        height: 48px;
        background: $primary_color;
        border-radius: 10px;
        margin-top: 12.5px;
        span {
            height: 19px;
            font-weight: 500;
            color: $white_color;
        }
    }
    .switch_container {
        display: inline-flex;
        align-items: center;
        column-gap: 9.41px;
        margin-top: 14px;
        & > .k-switch {
            width: 40px;
            height: 22px;
            & > .k-switch-track {
                height: 24px;
                width: 24px;
                outline: unset;
                & > .k-switch-thumb-wrap {
                    & > .k-switch-thumb {
                        width: 21px;
                        height: 21px;
                    }
                }
            }
        }
        & > .k-switch-on {
            border-color: $primary_color;
            color: white;
            background-color: $primary_color;
            & > .k-switch-track {
                border-color: $primary_color;
                background-color: $primary_color;
                & > .k-switch-thumb-wrap {
                    left: calc(100% - 11px);
                }
            }
        }
        & > .k-switch-off {
            border-color: #dadde3;
            background-color: #dadde3;
            color: white;
            & > .k-switch-track {
                border-color: #dadde3;
                background-color: #dadde3;
                & > .k-switch-thumb-wrap {
                    left: calc(11px);
                }
            }
        }
        & > .switch_label {
            @include font(12px, 500, #656565);
        }
        & > .switch_state {
            @include font(12px, 500, #aaaaaa);
        }
    }
    & > .k-dialog-content {
        box-shadow: 0px 4px 16px #00000010;
        & > .close_icon {
            position: absolute !important;
            top: 27px;
            inset-inline-end: 27px;
            color: #e4dcdc;
            font-size: 32px !important;
            padding: 0px;
            font-weight: 100 !important;
            cursor: pointer;
        }
        & > .dialog_title {
            text-align: center;
            height: 25px;
            font-weight: 600;
            color: $black-color;
            font-size: 20px;
            margin: 27px auto;
        }
        & > .dialog_container {
            min-height: 300px;
            max-width: 700px;
            background: $white_color !important;
            border-radius: 20px !important;
            @media (min-height: 950px) {
                height: 900px;
            }
            @media (max-width: 700px) {
                max-width: calc(100% - 10px);
            }
            @include flex(center, space-between);
            & > .select_class {
                margin-bottom: 30px;
                margin-top: 0px;
                width: calc(50% - 11px);
                @media (max-width: 440px) {
                    width: calc(100% - 11px);
                }
                & > .k-picker {
                    height: 58px;
                    background-color: $white_color;
                    background-image: none;
                }
            }
            height: calc(100vh - 143px);
            padding: 0px 15px 19px 37px;
            overflow: auto;
            &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
            }
            &::-webkit-scrollbar-track {
                background: #f2f2f2;
                border-radius: 20px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #5d5d5d;
                border-radius: 20px;
                border: 1px solid #5d5d5d;
            }
            @media (min-height: 900px) {
                max-height: 781px;
            }
        }
    }
    & > .k-window-content:last-child {
        padding-bottom: 20px;
    }
    & > .k-window-content:first-child {
        padding-top: 0px;
    }
}

.dialog_title {
    font-weight: bold;
    font-size: 16px;
}
.ant-modal-close {
    span {
        font-size: 20px;
    }
    &:hover {
        background-color: transparent !important;
    }
}
