@mixin selectAddress {
	.selectAddressContainer {
		.new-package--title {
			margin-bottom: 20px;
		}
		.Map {
			width: 100%;
			height: 258px;
			margin: 140px 0px 20px;
			& > div:nth-of-type(2) {
				height: inherit;
				border-radius: 12px;
				overflow: unset !important;
				& > div:nth-of-type(1) {
					overflow: hidden;
				}
				& > .MapSearchBox {
					position: absolute;
					top: -120px;
					width: 100%;
				}
			}
		}
	}
}
