@mixin RecommendStatus() {
    .RecommendStatusContainer {
        background: #f8f8f8;
        .RecommendStatusSubContainer {
            padding: 0px 0px 50px;
            max-width: 1640px;
            min-height: 455px;
            background: #f8f8f8;
            margin: auto;
            .RecommendStatus_Box {
                padding-top: 39px;
                width: calc(100% - 200px);
                margin: 0px auto 39px;
                @include flex(center, space-between);
                .RecommendStatus_Title {
                    @include font(46px, 600, $vibrarnt-black);
                    display: block;
                }
                .RecommendStatus_Box_Select {
                    width: 215px;
                    height: 62px;
                    border: 2px solid #aaaaaa;
                    border-radius: 12px;
                    @include font(18px, 500, #424242);
                    background-color: unset;
                    background-image: unset;
                    padding: 8px 8px;
                }
            }
            .RecommendStatus_Swiper {
                height: 355px;
                width: 100%;
                width: calc(100% - 200px);
                .swiper-wrapper {
                    margin: auto;
                }
                .swiper {
                    width: 100%;
                    height: 100%;
                }
                .swiper-button-prev {
                    width: 73px;
                    height: 73px;
                    background: #ededed;
                    border-radius: 50%;
                    &::after {
                        @include font(18px, 600, $black-color);
                    }
                }
                .swiper-button-next {
                    width: 73px;
                    height: 73px;
                    background: $white_color;
                    border-radius: 50%;
                    &::after {
                        @include font(18px, 600, $black-color);
                    }
                }
                .swiper-slide {
                    padding: 16px;
                    height: 352px;
                    width: 344px !important;
                    background: $white_color;
                    box-shadow: 0px 13px 35px #00000012;
                    border-radius: 12px;
                    .RecommendStatus_Swipper_Image {
                        position: relative;
                        width: 312px;
                        height: 189px;
                        margin-bottom: 17px;
                        .RecommendStatus_Swipper_ImageI {
                            width: 329px !important;
                            height: 189px !important;
                        }
                        .RecommendStatus_Badge {
                            position: absolute;
                            z-index: 1;
                            top: 12px;
                            right: 9px;
                            @include flex(center, center);
                            column-gap: 7px;
                            width: 143px;
                            height: 42px;
                            box-shadow: 0px 13px 16px #4cb78a29;
                            border-radius: 21px;
                            span {
                                width: 20px !important;
                                height: 19px !important;
                                position: relative !important;
                                img {
                                    position: relative !important;
                                }
                            }
                            p {
                                margin: 0px;
                                @include font(15px, bold, $white_color);
                            }
                        }
                    }
                    .RecommendStatus_Swipper_Rating {
                        @include flex(center, flex-start);
                        & > div {
                            @include font(14px, 500, $lightt-grey);
                        }
                        .k-rating-item.k-state-selected,
                        .k-rating-item.k-selected {
                            color: #ffb100;
                        }
                        .k-rating-container .k-rating-item .k-icon {
                            font-size: 16px;
                        }
                    }
                    .RecommendStatus_Swipper_Title {
                        @include font(18px, 600, $vibrarnt-black);
                        padding: 10px 0px 17px;
                        border-bottom: 1px solid #ebebeb;
                    }
                    .RecommendStatus_Swipper_BottomSec {
                        display: flex;
                        justify-content: space-between;
                        height: 45px;
                        align-items: center;
                        margin-top: 27px;
                        & > div {
                            @include font(22px, bold, $primary_color);
                            @include flex(center, flex-start);
                            column-gap: 5px;
                            min-width: 130px;
                            & > span {
                                @include font(18px, normal, $primary_color);
                            }
                        }
                        & > p {
                            width: 48px;
                            height: 44px;
                            background: #faad00;
                            box-shadow: 0px 13px 35px #00000012;
                            border-radius: 8px;
                            @include font(20px, bold, $white_color);
                            @include flex(center, center);
                        }
                    }
                }
            }
        }
        @media (min-width: 1440px) and (max-width: 1920px), (max-width: 1440px), (max-height: 768px) {
            .RecommendStatusSubContainer {
                max-width: 1370px;
                .RecommendStatus_Box {
                    .RecommendStatus_Title {
                        @include font(36px, 600, $vibrarnt-black);
                    }
                    .RecommendStatus_Box_Select {
                        width: 173px;
                        height: 50px;
                        border: 1px solid #aaaaaa;
                        border-radius: 8px;
                        @include font(13px, 500, #424242);
                    }
                }
                .RecommendStatus_Swiper {
                    height: 300px;
                    .swiper-slide {
                        padding: 11px;
                        height: 284px;
                        width: 278px !important;
                        box-shadow: 0px 13px 35px #00000012;
                        border-radius: 12px;
                        .RecommendStatus_Swipper_Image {
                            width: 257px;
                            height: 153px;
                            margin-bottom: 14px;
                            .RecommendStatus_Swipper_ImageI {
                                width: 257px !important;
                                height: 153px !important;
                            }
                            .RecommendStatus_Badge {
                                column-gap: 5px;
                                width: 116px;
                                height: 34px;
                                span {
                                    width: 16px !important;
                                    height: 15px !important;
                                }
                                p {
                                    @include font(12px, bold, $white_color);
                                }
                            }
                        }
                        .RecommendStatus_Swipper_Rating {
                            & > div {
                                @include font(12px, 500, $lightt-grey);
                            }
                            .k-rating-container .k-rating-item .k-icon {
                                font-size: 16px;
                            }
                        }
                        .RecommendStatus_Swipper_Title {
                            @include font(16px, 600, $vibrarnt-black);
                            padding: 9px 0px 10px;
                        }
                        .RecommendStatus_Swipper_BottomSec {
                            height: 45px;
                            margin-top: 10px;
                            & > div {
                                @include font(17px, bold, $primary_color);
                                & > span {
                                    @include font(14px, normal, $primary_color);
                                }
                            }
                            & > p {
                                width: 39px;
                                height: 35px;
                                @include font(16px, bold, $white_color);
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 1280px), (max-height: 800px) {
            .RecommendStatusSubContainer {
                max-width: 1217px;
                min-height: 450px;
                background: #f8f8f8;
                margin: auto;
                padding: 0px 0px 64px;
                .RecommendStatus_Box {
                    padding-top: 57px;
                    width: calc(100% - 200px);
                    margin: 0px auto 39px;
                    @include flex(center, space-between);
                    row-gap: 10px;
                    @media (max-width: 992px) {
                        width: calc(100% - 30px);
                    }
                    .RecommendStatus_Title {
                        @include font(28px, 600, $vibrarnt-black);
                        display: block;
                    }
                    .RecommendStatus_Box_Select {
                        width: 144px;
                        height: 42px;
                        border: 2px solid #aaaaaa;
                        border-radius: 12px;
                        @include font(12px, 500, #424242);
                        background-color: unset;
                        background-image: unset;
                        padding: 8px 8px;
                    }
                }
                .RecommendStatus_Swiper {
                    height: 253px;
                    width: calc(100% - 200px);
                    @media (max-width: 992px) {
                        width: calc(100% - 30px);
                    }
                    .swiper-wrapper {
                        margin: auto;
                    }
                    .swiper {
                        width: 100%;
                        height: 100%;
                    }
                    .swiper-button-prev {
                        width: 49px;
                        height: 49px;
                        background: #ededed;
                        border-radius: 50%;
                        &::after {
                            @include font(18px, 600, $black-color);
                        }
                    }
                    .swiper-button-next {
                        width: 49px;
                        height: 49px;
                        background: $white_color;
                        border-radius: 50%;
                        &::after {
                            @include font(18px, 600, $black-color);
                        }
                    }
                    .swiper-slide {
                        padding: 8px;
                        height: 252px;
                        width: 239px !important;
                        background: $white_color;
                        box-shadow: 0px 13px 35px #00000012;
                        border-radius: 12px;
                        .RecommendStatus_Swipper_Image {
                            position: relative;
                            width: 223px;
                            height: 134px;
                            margin-bottom: 10px;
                            .RecommendStatus_Swipper_ImageI {
                                width: 223px !important;
                                height: 134px !important;
                            }
                            .RecommendStatus_Badge {
                                position: absolute;
                                z-index: 1;
                                top: 12px;
                                right: 9px;
                                @include flex(center, center);
                                column-gap: 6px;
                                width: 99px;
                                height: 30px;
                                box-shadow: 0px 13px 16px #4cb78a29;
                                border-radius: 21px;
                                span {
                                    width: 14px !important;
                                    height: 13px !important;
                                    position: relative !important;
                                    img {
                                        position: relative !important;
                                    }
                                }
                                p {
                                    margin: 0px;
                                    @include font(10px, bold, $white_color);
                                }
                            }
                        }
                        .RecommendStatus_Swipper_Rating {
                            @include flex(center, flex-start);
                            & > div {
                                @include font(10px, 500, $lightt-grey);
                            }
                            .k-rating-item.k-state-selected,
                            .k-rating-item.k-selected {
                                color: #ffb100;
                            }
                            .k-rating-container .k-rating-item .k-icon {
                                font-size: 14px;
                            }
                        }
                        .RecommendStatus_Swipper_Title {
                            @include font(14px, 600, $vibrarnt-black);
                            padding: 5px 0px 11px;
                            border-bottom: 1px solid #ebebeb;
                        }
                        .RecommendStatus_Swipper_BottomSec {
                            display: flex;
                            justify-content: space-between;
                            height: 45px;
                            align-items: center;
                            margin-top: 9px;
                            & > div {
                                @include font(14px, bold, $primary_color);
                                @include flex(center, flex-start);
                                column-gap: 5px;
                                min-width: 130px;
                                & > span {
                                    @include font(11px, normal, $primary_color);
                                }
                            }
                            & > p {
                                width: 34px;
                                height: 31px;
                                background: #faad00;
                                box-shadow: 0px 13px 35px #00000012;
                                border-radius: 8px;
                                @include font(13px, bold, $white_color);
                                @include flex(center, center);
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 768px) {
            .RecommendStatusSubContainer {
                .RecommendStatus_Box {
                    width: calc(100% - 40px);
                    margin: 0px auto 27px;
                    .RecommendStatus_Title {
                        @include font(18px, 600, $vibrarnt-black);
                        display: block;
                    }
                }
                .RecommendStatus_Swiper {
                    width: calc(100% - 40px);
                }
            }
        }
    }
}
