@import "./packages";

.SearchContainer {
    background: $white_color;
    min-height: 100vh;
    @include Packages;
    & > .packages-content-wrapper {
        // border-top: 1px solid #e3e3e3;
    }
}

.popup-traveller-holder {
    width: 220px;
    height: 100px;
    border-radius: 20px;
    margin-top: 20px;
    &:lang(ar) {
        padding: 0 10px;
    }
}
