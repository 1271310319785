@import "./steps/packageType";
@import "./steps/selectAddress";
@import "./steps/availability";
@import "./steps/packageType";
@import "./steps/packageDetails";
@import "./steps/photos";
@import "./steps/pricing";

.package-form-wrapper {
	padding: 69px 8vw;
	padding-bottom: 20px;
	width: 100%;
	height: calc(100vh - 155px);
	overflow: auto;
	&.review-container {
		padding: 90px 0px 0px 0px;
	}
	.new-package--title {
		font-size: 28px;
		font-weight: 500;
		color: #171717;
		max-width: 70%;
		display: block;
		@media only screen and (max-width: 992px) {
			max-width: 100%;
			font-size: 24px;
		}
	}
	@media only screen and (max-width: 992px) {
		padding: 19px 10px;
		height: calc(100vh - 200px);
	}
}

.new-package-wrapper {
	display: flex;
	& .labelContainer {
		margin-bottom: 8px;
		& > .label {
			@include font(16px, 500, #171717);
		}
	}
	& > .newPackage_Stepper_Container {
		width: 320px;
		min-height: 100vh;
		background: #f5f5f5;
		padding-top: 28px;
		& > .newPackage_Stepper_Header {
			position: relative;
			height: 35px;
			width: 155px;
			margin: auto;
			cursor: pointer;
			img {
				object-fit: contain;
			}
		}
		& > .newPackage_Stepper_Content {
			margin-top: 80px;
			@include flex(flex-start, flex-start);
			flex-direction: column;
			row-gap: 24px;
			column-gap: 24px;
			padding: 0px 59px;
			position: sticky;
			top: 30px;
			.btn-back-list {
				display: flex;
				align-items: center;
				gap: 12px;
				&:lang(ar) {
					svg {
						transform: rotate(180deg);
					}
				}
			}
			.ant-steps {
				gap: 22px;
				.ant-steps-item {
					.ant-steps-item-content {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						justify-content: center;
						min-height: 42px;
					}
					.ant-steps-item-tail {
						display: none;
					}
					.ant-steps-item-title {
						font-size: 14px;
						font-weight: 500;
						color: #a0a0a0;
					}
					.ant-steps-item-icon {
						width: 42px;
						height: 42px;
						display: flex;
						align-items: center;
						justify-content: center;
						.ant-steps-icon {
							font-weight: 500;
						}
					}
					&.ant-steps-item-process {
						.ant-steps-item-icon {
							// background-color: #fff;
							// border-color: #5143f8;
							.ant-steps-icon {
								// color: #5143f8;
							}
						}
						.ant-steps-item-title {
							color: #000;
						}
					}
					&.ant-steps-item-finish {
						.ant-steps-item-icon {
							// background-color: #5143f8;
							.ant-steps-icon {
								color: #fff !important;
							}
						}
						.ant-steps-item-title {
							color: #000;
						}
					}
					&.ant-steps-item-error {
						.ant-steps-item-icon {
							border-color: #ff4d4f !important;
							.ant-steps-icon {
								color: #fff !important;
							}
						}
					}
				}
			}
		}
	}
	& > .newPackage_Body {
		background-color: $white_color;
		width: calc(100% - 320px);
		display: flex;
		flex-direction: column;
		@media (max-width: 992px) {
			width: 100%;
		}
		.new_Form {
			// min-height: calc(100vh - 169px);
			flex: 1;
			@include packageType;
			@include selectAddress;
			@include availability;
			@include packageDetails;
			@include photos;
			@include pricing;
			// @include review;
			@media only screen and (max-width: 992px) {
				> .ant-steps {
					gap: 30px;
					overflow: auto;
					padding: 10px 20px;
					padding-bottom: 0;
					&::-webkit-scrollbar {
						display: none;
					}
					.ant-steps-item-container {
						padding-bottom: 8px;
						.ant-steps-item-icon {
							display: inline-flex;
							align-items: center;
							justify-content: center;
						}
					}
					&.ant-steps-navigation .ant-steps-item::after {
						// margin-top: -10px;
						// margin-inline-start: 10px;
						display: none;
					}
				}
			}
		}
		.newPackage_Footer {
			border-top: 2px solid #d8d8d8;
			// min-height: 93px;
			padding: 12px 115px;
			position: relative;
			@include flex(center, space-between);
			gap: 10px;
			position: sticky;
			bottom: 0;
			background-color: #fff;

			& > .newPackage_Footer_Progress {
				position: absolute;
				left: 0px;
				height: 2px;
				// background: transparent linear-gradient(236deg, #363bfa 0%, #8457f4 100%);
				top: -2px;
			}
			& > .newPackage_Footer_Back {
				width: 140px;
				height: 52px;
				border: 1px solid #8d8d8d;
				border-radius: 8px;
				background-color: $white_color;
				@include font(14px, 500, #8d8d8d);
			}
			& > .newPackage_Footer_Right {
				// width: 100%;
				@include flex(center, space-between);
				column-gap: 32px;
				row-gap: 10px;
				margin-left: auto;
				&:lang(ar) {
					margin-left: 0;
					margin-right: auto;
				}
				& > .newPackage_Footer_Save {
					text-decoration: underline;
					font-size: 14px;
					font-weight: 500;
					cursor: pointer;
				}
				& > .newPackage_Footer_Next {
					width: 140px;
					height: 52px;
				}
			}

			@media (min-width: 786px) and (max-width: 970px) {
				padding: 19px 17px;
				border-top: 2px solid #d8d8d8;
				min-height: 80px;
				position: relative;
				@include flex(center, space-between);
				gap: 10px;
				& > .newPackage_Footer_Progress {
					position: absolute;
					left: 0px;
					height: 2px;
					// background-color: #542ef9;
					top: -2px;
				}
				& > .newPackage_Footer_Back {
					width: auto;
					height: 52px;
					border: 1px solid #8d8d8d;
					border-radius: 8px;
					background-color: $white_color;
					@include font(14px, 500, #8d8d8d);
				}
				& > .newPackage_Footer_Right {
					@include flex(center, center);
					flex-direction: column-reverse;
					column-gap: 32px;
					row-gap: 10px;
					& > .newPackage_Footer_Save {
						text-decoration: underline;
						@include font(14px, 500, #8d8d8d);
						cursor: pointer;
					}
					& > .newPackage_Footer_Next {
						width: 140px;
						height: 52px;
						background: #a7a7a7;
						border-radius: 8px;
						@include font(14px, 500, #e3e3e3);
					}
					& > .newPackage_Footer_Next_Active {
						// background: transparent linear-gradient(233deg, #363bfa 0%, #8457f4 100%);
					}
				}
			}
			@media (max-width: 992px) {
				padding: 19px 17px;
				border-top: 2px solid #d8d8d8;
				min-height: 93px;
				position: relative;
				@include flex(center, space-between);
				gap: 10px;
				& > .newPackage_Footer_Progress {
					position: absolute;
					left: 0px;
					height: 2px;
					// background-color: #542ef9;
					top: -2px;
				}
				& > .newPackage_Footer_Back {
					width: auto;
					height: 40px;
					border: 1px solid #eeeeee;
					border-radius: 8px;
					background-color: #eeeeee;
					@include font(14px, 600, #000);
				}
				& > .newPackage_Footer_Right {
					@include flex(center, center);
					column-gap: 10px;
					row-gap: 10px;
					& > .newPackage_Footer_Save {
						text-decoration: underline;
						font-size: 14px;
						font-weight: 700;
						cursor: pointer;
					}
					& > .newPackage_Footer_Next {
						width: auto;
						height: 40px;
						// background: #a7a7a7;
						border-radius: 8px;
						font-size: 14px;
						font-weight: 500;
					}
					& > .newPackage_Footer_Next_Active {
						// background: transparent linear-gradient(233deg, #363bfa 0%, #8457f4 100%);
					}
				}
			}
		}
	}
	& > .newPackage_FullBody {
		width: calc(100%);
		height: 100%;
	}
}
