.k-appbar {
	background-color: $white_color !important;
	justify-content: space-between;
	height: 76px;
	box-shadow: 0px 1px 0px rgb(216 216 216) !important;
	padding: 0px 45px;
	@media (max-width: $sideBar_Mobile_Breakpoint) {
		padding: 0px 27px;
	}
}

.appBar_userLogout {
	text-decoration: underline;
	font-weight: 500;
	color: #f85454 !important;
	font-size: 12px !important;
	width: 41px;
	height: 17px;
}

.appBar_user {
	font-weight: 500;
	color: #707070;
	font-size: 15px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	margin: 0px 10px;
}

.appBar_userSection {
	@include flex(center, flex-end);
}

.appBar_userSection_Functions {
	@include flex(center, center);
	column-gap: 11px;
	& > .appBar_notification {
		width: 40px;
		height: 40px;
		background: $white_color;
		box-shadow: 0px 3px 10px #00000015;
		border-radius: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		& > svg {
			position: relative !important;
			width: 16px !important;
			height: 16px !important;
		}
	}
	& > .appBar_setting {
		width: 40px;
		height: 40px;
		background: $white_color;
		box-shadow: 0px 3px 10px #00000015;
		border-radius: 12px;
		@include flex(center, center);
		cursor: pointer;
		& > svg {
			position: relative !important;
			width: 16px !important;
			height: 16px !important;
		}
		@media (max-width: $sideBar_Mobile_Breakpoint) {
			margin-right: 0px;
		}
	}
	& > .appBar_Lang {
		width: 40px;
		@include flex(center, center);
		column-gap: 9px;
		cursor: pointer;
		& > span {
			@include font(13px, 500, #7c7c7c);
		}
		& > svg {
			width: 9px;
		}
	}
}

.sideBar_userSection {
	@media (min-width: $sideBar_Mobile_Breakpoint) {
		display: none;
	}
	@include flex(center, space-between);
	border-bottom: 1px solid #d8d8d8;
	width: calc(100% - 34px);
	padding: 0px 0px 16px 0px;
	margin-bottom: 14px;
	& > div {
		@include flex(center, center);
	}
	& > span {
		font-size: 20px;
		color: $primary_color;
		cursor: pointer;
	}
	&.mobile-userSectoin {
		width: calc(100% - 65px);
		margin-inline-start: 25px;
	}
}

.appBar_userSection_expanded {
	position: absolute;
	top: 80px;
	display: flex;
	align-items: center;
	flex-direction: column;
	& > .appBar_name {
		font-style: normal;
		font-weight: 500;
		color: #707070;
		font-size: 15px;
		margin-top: 9px;
	}
	& > .appBar_role {
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		color: #aaaaaa;
	}
	& .k-avatar-image {
		@include flex(center, center);
	}
}

.ant-layout-header {
	padding-inline: 0 !important;
	.header-container {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-inline: 20px 20px !important;

		.ant-breadcrumb {
			@media (max-width: $sideBar_Mobile_Breakpoint) {
				max-width: 7rem;
			}
		}

		.appBar_user {
			line-height: 25px;
		}
	}
}

.appBar_breadSection {
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	column-gap: 30px;
	text-transform: capitalize;
	& > span {
		cursor: pointer;
	}
}

.appbar-separator {
	height: 23px;
	border: 1px solid #d8d8d8;
	color: #d8d8d8;
	margin: 0px 20px !important;
	@media (max-width: 984px) {
		display: none;
	}
}
