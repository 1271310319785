@mixin translate_btn($width: 72px, $height: 29px, $fontSize: 9px) {
    position: absolute;
    right: 4px;
    top: calc(50% + 10px);
    height: $height;
    width: $width;
    border-radius: 6px;
    font-weight: 500;
    color: $white_color;
    font-size: $fontSize;
    transform: translateY(-50%);
    border: 0px;
    @include flex(center, center);
    column-gap: 5px;
    & > svg {
        width: 19px;
        height: 17px;
    }
    @media (max-width: 785px) {
        width: 40px;
        & > .k-button-text {
            display: none;
        }
    }
}

@mixin common_input_class {
    position: relative;
    width: 100%;
    height: inherit;
    & > .labelContainer {
        @include flex(center, flex-start);
        gap: 5px;
        margin-bottom: 4px;
        & > .label {
            font-weight: 600;
            color: $black-color;
            font-size: 12px;
        }
        & > .subLabel {
            @include font(12px, 400, #8d8d8d);
        }
    }
    & > input,
    input.k-input {
        height: 100%;
        width: 100%;
        border: 1px solid #dadde3;
        border-radius: 8px;
        padding-inline-start: 21px;
        @include font(12px, 500, $black-color);
        &:focus {
            border: 1px solid $black-color;
            box-shadow: unset;
            color: $black-color;
        }
        &::placeholder {
            font-weight: 500;
            color: #a7a7a7;
            font-size: 12px;
        }
        &:disabled {
            background: #ededed;
            border: 1px solid #c7c7c7;
        }
    }
    & > svg {
        position: absolute;
        top: calc(50% + 5px);
        left: 15px;
        right: 15px;
    }
    .translate_btn_active {
        @include translate_btn(105px, 40px, 12px);
        background: $primary_color;
    }
    .translate_btn_inactive {
        @include translate_btn(105px, 40px, 12px);
        background: #333333;
    }
}

.input_class_startIcon {
    @include common_input_class;
    & > input,
    input.k-input {
        padding: 0px 35px !important;
        &:focus {
            border: 1px solid $black-color;
            box-shadow: unset;
            color: $black-color;
        }
        &::placeholder {
            font-weight: 500;
            color: #a7a7a7;
            font-size: 12px;
        }
        &:disabled {
            background: #ededed;
            border: 1px solid #c7c7c7;
        }
    }
}

.input_class {
    @include common_input_class;
    & > .k-form-error {
        @include font(12px, 400, #f31700);
        font-style: normal;
    }
    & > .eye_password {
        position: absolute;
        right: 10px;
        bottom: 5px;
        cursor: pointer;
        & > svg {
            & > path {
                stroke: #a7a7a7;
            }
        }
        &:lang(ar) {
            left: 10px;
            right: unset;
        }
    }
}
