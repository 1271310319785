@mixin trip_information() {
	padding: 0px 21px 43px;
	padding-bottom: 25px;
	margin-bottom: 50px;
	position: relative;
	// border-left: 2px solid #a7a7a7;
	&:after {
		content: "";
		display: block;
		position: absolute;
		width: 13px;
		height: 13px;
		left: -6px;
		top: -3px;
		box-sizing: border-box;
		border-radius: 100px;
		border: 3px solid #4f7624;
	}
	&:before {
		position: absolute;
		display: block;
		left: 0;
		top: 25px;
		height: 100%;
		border: 1px solid;
		color: #4f7624;
		content: "";
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
	&:lang(ar) {
		border-right: 2px solid #a7a7a7;
		border-left: 0;
		&::after {
			left: auto;
			right: -6px;
		}
		&:before {
			left: auto;
			right: 0;
		}
	}
	@include flex(flex-start, flex-start);
	flex-wrap: unset;
	gap: 16px;
	& > .trip_information_img {
		width: 150px;
		min-width: 150px;
		height: 113px;
		border-radius: 8px;
		position: relative;
		.ant-image {
			width: 100% !important;
			height: 100% !important;
			img {
				object-fit: contain;
				height: 100% !important;
			}
		}
		& > span {
			width: 150px !important;
			height: 113px !important;
			position: relative !important;
			border-radius: 8px;
			img {
				object-fit: cover;
			}
		}
	}
	& > .trip_information_info {
		@include flex(flex-start, flex-start);
		flex-direction: column;
		gap: 9px;
		& > .trip_information_badge {
			min-width: 96px;
			height: 33px;
			border-radius: 21px;
			padding: 0px 10px;
			font-size: 12px;
			font-weight: 500;
			@include flex(center, center);
		}
		& > .trip_information_title {
			@include font(18px, 500, $black-color);
			// margin: 8px 0;
			line-height: 1.4;

			@media only screen and (max-width: 992px) {
				width: 80%;
			}
			@media only screen and (max-width: 500px) {
				width: 100%;
			}
		}
		& > .trip_information_location {
			@include flex(flex-start, flex-start);
			flex-wrap: nowrap;
			gap: 9px;
			min-width: 40px;
			@media only screen and (max-width: 992px) {
				min-width: 60vw;
			}
			svg {
				width: 30px;
				margin-bottom: 4px;
			}
			& > .trip_information_location-p {
				@include font(12px, 500, #8d8d8d);
				text-decoration: underline;
				margin: 0px;
			}
		}
		& > .trip_information_from {
			@include flex(center, flex-start);
			gap: 21px;
			& > p {
				@include font(12px, 500, #2b2f33);
				margin: 0px;
			}
		}
		& > .trip_information_desc {
			margin: 0px;
			@include font(16px, 400, $black-color);
			line-height: 1.6;
			@media only screen and (max-width: 992px) {
				width: 100%;
			}
		}
	}
}

.package-details-row-holder {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 24px;
	margin-top: 23px;
	margin-bottom: 23px;
	@media only screen and (max-width: 992px) {
		flex-direction: column;
		align-items: flex-start;
		gap: 0;
		margin-bottom: 13px;
	}
}

.package-provider-holder {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #f2f2f0;
	border-radius: 8px;
	width: 278px;
	min-width: 278px;
	img {
		width: 100%;
		height: 100% !important;
		border: 1px solid #cfd8e3;
		border-radius: 8px;
		object-fit: cover;
		&.img-next-company {
			width: 48px;
			height: 48px;
			border: 0 !important;
		}
	}
	a {
		display: flex;
		align-items: center;
		gap: 16px;
		.provider--name {
			font-size: 16px;
			font-weight: 400;
			color: #343434;
		}
		text-decoration: none;
	}
	@media only screen and (max-width: 992px) {
		width: 100%;
	}
}
.seats-row-details {
	// height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.seats--title {
		color: #343434;
		font-size: 14px;
		font-weight: 400;
		&h5 {
			font-size: 17px;
			font-weight: 700;
			color: #343434;
		}
	}
	.seats--available {
		color: #595959;
		font-weight: 400;
		font-size: 14px;
	}
	.seats--count {
		font-size: 14px;
		color: #343434;
	}
	.seats--price {
		font-size: 17px;
		font-weight: 700;
		color: #343434;
		span {
			color: #595959;
			font-size: 12px;
			font-weight: 500;
		}
	}
}
.seats-info-holder {
}
.ticket-details-wrapper {
	border: 1px solid #d6d9d3;
	box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}
.review-container {
	// padding: 19px 17px;
	// max-width: 1200px;
	// margin: 0 auto;
	// display: flex;
	// flex-direction: column;
	padding-top: 20px;
	height: auto !important;
	&.package-form-wrapper {
		padding-top: 100px;
		// .review-container_TripTicket {
		// 	.review-container_Ticket {
		// 		margin-inline-end: 40px;
		// 	}
		// }
	}
	.packge-header__location {
		display: flex;
		align-items: center;
		margin-top: 12px;
		.info-holder {
			display: flex;
			align-items: center;
			gap: 6px;
			svg {
				font-size: 22px;
				color: #a7a7a7;
			}
			.info__text {
				color: #484848;
				font-size: 14px;
				@media only screen and (max-width: 992px) {
					font-size: 13px;
				}
			}
		}
	}
	.package-info-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 12px;
		flex: 1;
		height: 48px;
		border-top: 1px solid #f2f2f0;
		border-bottom: 1px solid #f2f2f0;
		.info-holder {
			display: flex;
			align-items: center;
			@media (min-width: 768px) {
				// border-right: 1px solid #f2f2f0;
			}
			// padding-right: 12px;
			&:last-of-type {
				border-right: 0 !important;
				border-left: 0 !important;
			}

			svg {
				margin-right: 12px;
				font-size: 22px;
				color: #a7a7a7;
				&:lang(ar) {
					margin-right: 0;
					margin-left: 12px;
				}
			}
			.ant-tag {
				height: 32px;
				svg {
					color: inherit;
					margin: 0;
				}
			}
			.info__text {
				display: flex;
				align-items: center;
				gap: 4px;
				color: #484848;
				font-size: 14px;
				@media only screen and (max-width: 992px) {
					font-size: 13px;
				}
			}
			&:lang(ar) {
				// border-left: 1px solid #f2f2f0;
				// padding-left: 12px;
				// margin-left: 12px;
				// margin-right: 0;
				// padding-right: 0;
				border-right: 0;
			}
		}
	}
	.review-actions-container {
		width: 100%;
		position: fixed;
		z-index: 3;
		background: #fff;
		border-bottom: 1px solid #e3e3e3;
		top: 70px;
		left: 0px;
		padding: 15px 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		@media only screen and (max-width: 992px) {
			top: 56px;
		}
		.review-container_Actions {
			width: 100%;
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;
			align-items: center;

			@media only screen and (max-width: 992px) {
				flex-direction: column-reverse;
				align-items: flex-start;
				gap: 15px;
				padding-inline-start: 8px;
			}
			& > .review-container_Actions_Buttons {
				@include flex(center, center);
				gap: 25px;
				text-align: center;

				& > .review-container_Actions_Save {
					width: 180px;
					height: 40px;
					// border: 2px solid $primary_color;
					// background: transparent;
					border-radius: 10px;
					font-size: 14px;
					font-weight: 600;
					// color: $primary_color;
				}
				& > .review-container_Actions_Publish {
					width: 180px;
					height: 40px;
					// background: $primary_color;
					// border-radius: 10px;
					// @include font(14px, 600, $white_color);
				}

				@media only screen and (max-width: 992px) {
					justify-content: space-evenly;
					gap: 5px;
					width: 100%;
					flex-wrap: nowrap;
				}
			}
			& > .review-container_Actions_Back {
				text-align: center;
				text-decoration: underline;
				cursor: pointer;
				@include font(14px, 600, $primary_color);
				color: #999;
				border-color: #f8f5ff;
				background-color: #f8f5ff;
				padding: 8px 10px;
				border-radius: 8px;

				& > span {
					margin: 0px 5px;
				}
				&:hover {
					color: #555;
					background-color: #efefef;
				}
			}
		}
	}
	.review-container_head {
		.anticon-heart {
			@media only screen and (max-width: 768px) {
				display: none !important;
			}
		}
	}

	.review-container_head {
		@include flex(center, space-between);
		gap: 20px;
		.review-header-info-holder {
			display: flex;
			align-items: center;
			.review-header-info__title {
				@include font(22px, 500, $vibrarnt-black);
				display: block;
				margin: 0px;
			}
			.review-container_Location_Name {
				text-decoration: underline;
				@include font(16px, 500, #484848);
				margin-bottom: 0;
			}
		}

		& > p {
			@include font(22px, 500, $vibrarnt-black);
			display: block;
			margin: 0px;
		}
		& > span {
			font-size: 25px;
			color: $black-color;
			cursor: pointer;
		}
		&.package-fav {
			.anticon-heart {
				background-color: #ed6d00;
				color: #fff;
				border-color: #fff;
			}
		}
	}
	.review-container_Location_Experience {
		@include font(14px, 500, #0a7db9);
		margin-bottom: 0;
		margin-left: 20px;
		&:lang(ar) {
			margin-left: 0;
			margin-right: 20px;
		}
	}
	.review-container_Location {
		@include flex(center, flex-start);
		column-gap: 10px;
		margin-top: 14px;
		& > .dot {
			@include font(25px, 500, $black-color);
			padding-bottom: 10px;
		}
		& > .review-container_Location_Name {
			text-decoration: underline;
			@include font(16px, 500, #484848);
			margin-bottom: 0;
		}
		& > .review-container_Location_Experience {
			@include font(14px, 500, $primary_color);
			margin-bottom: 0;
		}
	}
	.package-header-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
	.share-fav-holder {
		display: flex;
		align-items: center;
		gap: 22px;
		.primary-light {
			// background-color: #f7f5ff !important;
			border: 1px solid;
			color: #3f464e !important;
		}
		.anticon-heart {
			width: 39px;
			height: 39px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid;
			// background-color: #f7f5ff;
			border-radius: 50%;
			// backdrop-filter: blur(8px);

			&.package-fav {
				background-color: #ed6d00;
				color: #fff;
				border-color: #fff;
			}
			@media only screen and (max-width: 768px) {
				display: none !important;
			}
		}
	}
	& > .review-container_TripTicket {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin: 32px auto;
		margin-top: 0;
		gap: 24px;
		flex-wrap: nowrap;
		position: relative;
		@media (max-width: 992px) {
			flex-direction: column;
			align-items: center;
		}
		& > .review-container_Trip {
			// max-width: 834px;
			width: 100%;
			@media (max-width: 992px) {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 48px;
			}
			& > .review-container_PackageDetails {
				// max-width: 834px;
				width: 100%;
				min-height: 263px;
				background: #fafafa;
				border: 1px solid #c6c6c6;
				border-radius: 8px;
				margin-bottom: 44px;
				padding: 29px 35px;
				@include flex(flex-start, flex-start);
				row-gap: 20px;
				& > div {
					border-left: 1px solid #c6c6c6;
					@include flex(flex-start, flex-start);
					gap: 19px;
					flex-direction: column;
					padding: 0px 20px;
					&:first-of-type,
					&:nth-of-type(5) {
						border-left: 0px;
					}
					& > p {
						@include font(18px, 600, #2b2f33);
					}
					& > span {
						@include flex(flex-start, flex-start);
						gap: 10px;
						flex-direction: column;
						& > b {
							@include font(16px, 400, #8d8d8d);
						}
					}
				}
			}

			& > .review-container_TripActivities {
				// max-width: 834px;
				width: 100%;
				.package__description-holder {
					margin: 20px 0px 40px 0px;
					* {
						line-height: 1.6;
					}
				}
				& > span {
					@include font(20px, 600, #2b2f33);
				}
				& > .review-container_TripActivities_Section {
					margin-top: 16px;
					width: 100%;
					min-height: 140px;
					display: grid;
					grid-template-columns: 1fr 1fr 1fr 1fr;
					align-items: center;
					border-radius: 8px;
					padding: 24px 10px;
					@media (max-width: 992px) {
						grid-template-columns: repeat(2, 1fr);
						grid-gap: 20px;
						justify-items: start;
					}
					@media (max-width: 500px) {
						grid-template-columns: repeat(2, 50%);
						grid-gap: 0;
						padding: 0;
						& > .item {
							width: 100%;
						}
					}
					& > .item {
						@include flex(center, center);
						// flex-direction: row;
						flex-direction: column;
						flex-wrap: unset;
						gap: 14px;
						padding: 5px 28px;
						height: auto;
						border-left: 1px solid #ced3f2;
						flex: 1;
						&:lang(ar) {
							border-right: 1px solid #ced3f2;
							border-left: 0;
						}
						svg {
							min-width: 32px;
						}
						@media (max-width: 992px) {
							padding: 28px;
							&:nth-of-type(odd) {
								border-left: 0px;
							}
						}
						&:first-of-type {
							border-left: 0px !important;
							border-right: 0px !important;
						}
						& > div {
							& > span {
								@include font(14px, 600, #484848);
								display: -webkit-box;
								-webkit-line-clamp: 2;
								-webkit-box-orient: vertical;
								overflow: hidden;
							}
							& > p {
								margin: 0px;
								@include font(14px, 400, #484848);
								display: -webkit-box;
								-webkit-line-clamp: 2;
								-webkit-box-orient: vertical;
								overflow: hidden;
							}
						}
					}
				}
			}
			& > .review-container_TripStart {
				margin-top: 51px;
				.start-trip-desc {
					display: flex;
					flex-direction: column;
				}
				& > span {
					position: relative;
					padding: 0px 24px;
					@include font(23px, 600, #343434);
					& > p,
					& > a {
						display: flex;
						align-items: center;
						gap: 8px;
						color: #343434;
						margin: 0px;
						font-size: 15px;
						font-weight: 400;
						margin-top: 8px;
						max-width: 80%;
						margin-inline-start: -29px;
						svg {
							font-size: 30px;
							color: #949e89;
						}
					}
					& ::before {
						content: "";
						width: 13px;
						height: 13px;
						left: 4px;
						top: 10px;
						position: absolute;
						box-sizing: border-box;
						// transform: translateY(-50%);
						border-radius: 100px;
						border: 3px solid #4f7624;
					}
					&:lang(ar) {
						& ::before {
							right: 4px;
							left: auto;
						}
					}
				}
				& > p {
					@include font(14px, 400, #2b2f33);
					margin: 7px 20px 0px;
				}
				& > div:first-of-type {
					margin-top: 20px;
					height: 275px;
					max-width: 832px;
					width: 100%;
					@media (max-width: 992px) {
						width: 90vw;
					}
					& > div {
						height: 275px;
						max-width: 832px;
						width: 100%;
						border-radius: 10px;
					}
				}
				& > .review-container_TripMapLocation {
					margin: 20px 0px 45px;
					.review-container_TripMapLocation-row {
						display: flex;
						align-items: center;
						& > .k-icon {
							@include font(25px, 500, #d0d0d0);
						}
						& > p {
							@include font(14px, 400, #2b2f33);
							margin: 0px 10px;
							display: inline-block;
						}
					}
					@media only screen and (max-width: 992px) {
						margin-bottom: 0;
					}
				}
			}
		}
		.review-container_Ticket {
			width: 278px;
			min-width: 278px;
			position: sticky;
			top: 80px;
			* {
				user-select: none;
			}
			@media (max-width: 1200px) {
				width: auto;
				min-width: initial;
			}
			@media (max-width: 992px) {
				width: 100%;
				position: static;
			}
			@media (max-width: 500px) {
				width: 100%;
				min-width: initial;
			}
			& > span {
				@include font(17px, 500, #000);
			}
			.ant-form-item-label {
				label {
					color: #4f4f4f;
					font-size: 14px;
				}
			}
			.ant-picker-input {
				input {
					color: #000;
				}
			}
			& > .review-container_TicketContainer {
				width: 100%;
				min-height: 323px;
				background: $white_color;
				// box-shadow: 0px 13px 46px #0000001c;
				box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
				border-radius: 8px;
				padding-top: 18px;
				.ticket-control-wrapper {
					padding: 0 16px;
				}
				.ticket-details-wrapper {
					padding: 16px;
					.ticket-icon-txt {
						display: flex;
						align-items: center;
						gap: 8px;
						font-size: 12px;
						font-weight: 500;
						color: #595959;
						margin-bottom: 10px;
						svg {
							font-size: 22px;
							color: #949e89;
						}
					}
				}
				.seats-header {
					display: flex;
					justify-content: center;
					align-items: center;
					margin-bottom: 1rem;
					color: #a7a7a7;
				}
				.ticket_Counter {
					width: 100%;
					@include flex(center, space-between);
					border: 1px solid #d6d9d3;
					border-radius: 8px;
					padding: 8px 10px;
					margin-bottom: 16px;
					.ticket_Counter_Function {
						width: 100%;
						.ant-input-number-group-wrapper {
							width: 100%;
						}
						.ant-input-number-group {
							display: flex;
							align-items: center;
							justify-content: center;
							.ant-input-number {
								background-color: transparent;
								text-align: center;
							}

							.ant-input-number-group-addon {
								width: fit-content;
								background-color: transparent;
								border: 0;
								cursor: pointer;
								padding: 0;
								.anticon {
									font-size: 26px;
									&[disabled] {
										pointer-events: none;
										opacity: 0.3;
									}
								}
							}
						}
						.ant-input-number-input-wrap {
							user-select: none;
						}
						.ant-input-number-input {
							width: 51px;
							padding: 0;
							text-align: center;
							user-select: none;
							font-size: 20px;
							font-weight: 500;
							color: #2b2f33;
						}
					}
				}
				& > button {
					// height: 47px;
				}
			}
			.review-container_FormTitle {
				font-size: 18px;
				font-weight: 500;
				color: #343434;
				margin-bottom: 15px;
			}
		}
	}
	.ticket-Price-card {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		// margin-top: 21px;
		border-top: 1px solid #e3e3e3;
		padding: 20px 0;
		.ticket_Counter_title {
			font-size: 14px;
			font-weight: 500;
			color: #343434;
		}
		.ticket_Price--tilte {
			color: #2b2f33;
			font-size: 12px;
			font-weight: 500;
			user-select: none;
			padding-top: 5px;
		}
		.ticket_Price--total-price {
			font-size: 22px;
			font-weight: 600;
			display: flex;
			align-items: center;
			gap: 5px;
			user-select: none;

			.currency {
				font-size: 12px;
				font-weight: 500;
				color: #595959;
			}
		}
		.price-include {
			margin-top: 10px;
		}
		.ticket_Price--price {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			.price-include {
				font-size: 12px;
				color: #a7a7a7;
			}
		}
	}
	.review-container_whatYouWill_container {
		// max-width: 834px;
		& > span {
			@include font(20px, 600, #2b2f33);
		}
		& > .review-container_whatYouWill {
			width: 100%;
			margin: 50px auto;
			list-style: none;
			padding-inline-start: 0px;
			& > .trip_information_inactive {
				@include trip_information;
				&:before {
					display: none;
				}
			}
			& > .trip_information_active {
				@include trip_information;
				&:after {
					border: 3px solid #4f7624;
				}
				@media only screen and (max-width: 992px) {
					flex-direction: column;
					.trip_information_info {
						flex-direction: initial;
					}
				}
			}
			& > .trip_information {
				@include trip_information;
				&:before {
					height: 50%;
				}
				&:after {
					border: 3px solid $primary_color;
				}
			}
		}
	}
	.review-container_whatIncludes {
		width: 100%;
		border-top: 1px solid #d5d5d5;
		padding: 45px 0px 43px;
		& > span {
			@include font(20px, 600, #2b2f33);
			margin: 0px 0px 22px;
			display: block;
		}
		& > .review-container_whatIncludesSentence {
			@include flex(flex-start, flex-start);
			flex-direction: column;
			column-gap: 15px;
			margin-bottom: 13px;
			* {
				line-height: 1.6;
			}
			p {
				font-size: 16px;
			}
			@media (max-width: 1200px) {
				flex-wrap: nowrap;
			}
			li {
				@include font(16px, 400, $black-color);
				margin: 0px;
				margin-left: 20px;
			}
		}
	}
	.review-container_cancellation {
		width: 100%;
		border-top: 1px solid #d5d5d5;
		padding: 45px 0px 89px;
		& > span {
			@include font(20px, 600, #2b2f33);
			margin: 0px 0px 33px;
			display: block;
		}
		& > p {
			max-width: 750px;
			margin: 0px;
			@include font(17px, 400, $black-color);
			margin-bottom: 20px;
			line-height: 1.6;
			#more {
				display: none;
			}
		}
	}
	@media only screen and (max-width: 992px) {
		padding-top: 20px;
		position: relative;
		// padding: 0;
		.package-info-wrapper {
			height: auto;
			flex-wrap: wrap;
			gap: 8px;
			margin-bottom: 20px;
			margin-top: 0;
			padding: 12px 0;
		}
		.review-container_head {
			position: static;
			.review-header-info-holder {
				flex-wrap: wrap;
			}
		}
		.review-container_TripStart {
			margin-top: 0px !important;
			width: 100%;
			> span {
				display: flex;
				flex-wrap: wrap;
			}
			.trip_information_location-p {
				max-width: 100% !important;
				flex: 1;
			}
		}
		.map-info-holder {
			width: auto !important;
		}
	}
}

.antd-moadal {
	.ant-modal-content {
		padding: 0px;
	}
	& .dialog_packageCreated_container {
		min-width: 516px;
		max-width: 616px;
		min-height: 400px;
		padding: 62px 97px 46px;
		text-align: center;
		@media (max-width: 550px) {
			padding: 50px 20px;
			min-width: initial;
		}
		& > .first_section {
			& > .dialog_img {
				position: relative;
				margin: 0px auto 17px;
				width: 78px;
				height: 78px;
			}
			& > .dialog_title {
				margin-bottom: 62px;
				@include font(24px, 500, $black-color);
				& > p {
					margin: 5px auto 0px;
					@include font(24px, 500, #57a721);
				}
			}
		}
		& > .id_section {
			margin-top: 45px;
			@include flex(flex-start, flex-start);
			gap: 15px;
			flex-direction: column;
			& > span {
				@include font(18px, 500, #000000);
			}
			& > div {
				min-height: 60px;
				background: #f4f4f4;
				border-radius: 10px;
				width: 100%;
				@include flex(center, space-between);
				flex-wrap: unset;
				gap: 20px;
				padding: 0px 28px;
				& > span {
					cursor: pointer;
				}
				& > b {
					@include font(14px, 500, #8d8d8d);
				}
			}
		}
		& > .TicketCopied {
			@include font(14px, 500, #57a721);
			margin: 20px auto 0px;
		}
		& > button {
			font-size: 16px;
			font-weight: 600;
			width: 100%;
			height: 60px;
			border-radius: 8px;
			margin-top: 20px;
		}
	}
}

.map-info-holder {
	> div {
		&:first-of-type {
			display: none;
		}
	}
}

.imagesModalStyle {
	width: 90vw !important;
	.ant-modal-content {
		border-radius: 12px;
		.ant-modal-close {
			margin: 20px;
			margin-top: 5px;
			@media (max-width: 1024px) {
				margin-top: 0;
			}
			.anticon-close {
				svg {
					width: 2rem;
					height: 2rem;
				}
			}
		}
		.ant-modal-body {
			padding: 45px 10px 20px;
			padding-bottom: 0;
			@media (max-width: 1024px) {
				padding: 0;
				padding-top: 40px;
			}
			.packagesModal_carousel {
				display: flex;
				flex-direction: column;
				gap: 10px;
				.ant-carousel {
					.main_carousel {
						height: 75vh;
						@media (max-width: 1024px) {
							height: 70vh;
							max-height: 700px;
						}
						.slick-arrow {
							font-size: 3rem;
							color: #fff;
							width: 60px;
							height: 60px;
							display: flex !important;
							align-items: center;
							justify-content: center;
							z-index: 2;
							inset-inline: unset;
							svg {
								width: 100%;
								height: 100%;
							}
							&:hover,
							&:focus {
								color: #fff;
							}
							&.slick-prev {
								inset-inline-start: 0;
							}
							&.slick-next {
								inset-inline-end: 0;
							}
							@media (max-width: 1024px) {
								display: none !important;
							}
						}
						.slick-list {
							height: 100%;
							* {
								height: 100%;
							}
							.slick-track {
								.slick-slide {
									.ant-image {
										width: 100%;
										padding: 0;
										.ant-image-img {
											width: 100%;
											border-radius: 8px;
											// object-fit: cover;
											object-fit: contain;
										}
									}
								}
							}
						}
					}
					.sub_carousel {
						height: 10vh;
						.slick-list {
							height: 100%;
							* {
								height: 100%;
								object-fit: cover;
							}
							.slick-track {
								display: flex;
								gap: 10px;
								.slick-slide {
									max-width: 150px;
									opacity: 0.6;
									border-radius: 8px;
									&.slick-current {
										opacity: 1;
										border: 2px solid #5143f8;
									}
									.ant-image {
										width: 100%;
										.ant-image-img {
											width: 100%;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
