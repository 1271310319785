.profileContainer {
	.personalDetails_Content {
		& > .personalDetails_Content_Header {
			@include flex(flex-start, space-between);
			flex-wrap: nowrap;

			@media only screen and (max-width: 992px) {
				padding-left: 16px;
				padding-right: 16px;
			}
			@media only screen and (max-width: 520px) {
				// flex-direction: column;
			}
			& > .personalDetails_Content_Title {
				@include flex(flex-start, space-between);
				flex-direction: column;
				margin-bottom: 14px;
				width: 100%;
				& > .personalDetails_Content_Title_P {
					@include font(28px, 600, $vibrarnt-black);
					@include flex(center, space-between);
					gap: 20px;
					margin: 0px;
					& > .profile_review {
						display: flex;
						align-items: center;
						justify-content: center;
						@include font(14px, 400, #f31e1e);
						background: #f31e1e1a;
						border-radius: 17px;
						flex-wrap: nowrap;
						gap: 5px;
						height: 34px;
						padding: 7px 20px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						&.--approved {
							background: #57a72133;
							color: #57a721;
						}
					}
					@media only screen and (max-width: 520px) {
						font-size: 23px !important;
					}
				}
				& > .personalDetails_Content_SubTitle_P {
					@include font(16px, 400, #5a5a5a);
					margin: 10px 0;
				}
			}
		}
	}
}

// .cloneDialogStyle {
//     & > .k-dialog {
//         background: #edffed;
//         box-shadow: 0px 4px 16px #00000010;
//         border-radius: 12px;
//         & > .k-window-content {
//             &:last-child {
//                 padding-bottom: 0px;
//             }
//             & > .body {
//                 max-width: 494px;
//                 margin: auto;
//                 text-align: center;
//                 padding: 60px 32px 45px;
//                 @media only screen and (max-width: 992px) {
//                     padding: 40px 16px 35px;
//                 }
//                 & > p {
//                     margin: 23px 0px 0px;
//                     @include font(18px, 400, $black-color);
//                     min-height: 145px;
//                     line-height: 35px;
//                     text-align: center;
//                 }
//                 & > div {
//                     @include flex(center, center);
//                     flex-wrap: unset;
//                     gap: 28px;
//                     @media only screen and (max-width: 992px) {
//                         margin-top: 10px;
//                     }
//                     & > .done {
//                         width: 200px;
//                         height: 60px;
//                         background: var(--main-color) 0% 0% no-repea;
//                         background: #542ef9;
//                         border-radius: 10px;
//                         @include font(16px, 600, $white_color);
//                     }
//                     & > .edit {
//                         @include font(16px, 600, $primary_color);
//                         width: 200px;
//                         height: 60px;
//                         border: 2px solid #542ef9;
//                         border-radius: 10px;
//                     }
//                 }
//             }
//         }
//     }
// }

.info_modal {
	&.clone_modal {
		& > .ant-modal-content {
			background: #edffed;
		}
	}
	&.warning_modal {
		& > .ant-modal-content {
			background: #ffefed;
		}
	}
	& > .ant-modal-content {
		border-radius: 20px;
		& > .ant-modal-body {
			& > .body {
				max-width: 494px;
				margin: auto;
				text-align: center;
				padding: 60px 0px 45px;
				@media only screen and (max-width: 992px) {
					padding: 40px 6px 35px;
				}
				& > p {
					margin: 23px 0px 0px;
					@include font(16px, 400, $black-color);
					min-height: 145px;
					line-height: 35px;
					text-align: center;
					& > b {
						display: block;
						@include font(17px, 700, #db3b21);
					}
				}
				& > div {
					@include flex(center, center);
					flex-wrap: unset;
					gap: 28px;
					@media only screen and (max-width: 992px) {
						margin-top: 10px;
					}
					& > .main_button {
						min-width: 210px;
						height: 60px;
						// background: #542ef9;
						border-radius: 10px;
						font-size: 16px;
						font-weight: 600;
					}
					& > .sub_button {
						min-width: 200px;
						height: 60px;
						font-size: 16px;
						font-weight: 600;
						border-width: 2px;
						border-radius: 10px;
						background-color: transparent;
					}
					@media only screen and (max-width: 992px) {
						& > .sub_button,
						& > .main_button {
							min-width: auto;
							flex: 1;
						}
					}
				}
			}
		}
	}
}
.warnDialogStyle.ant-modal {
	box-shadow: 0px 4px 16px #00000010;

	.body {
		max-width: 494px;
		margin: auto;
		text-align: center;
		padding: 60px 32px 45px;
		@media only screen and (max-width: 992px) {
			padding: 40px 16px 35px;
		}
		& > p {
			margin: 23px 0px 0px;
			@include font(14px, 400, $black-color);
			height: 145px;
			line-height: 35px;
			text-align: center;
			& > b {
				display: block;
				@include font(18px, 500, $black-color);
			}
		}
		& > div {
			@include flex(center, center);
			flex-wrap: unset;
			gap: 28px;
			@media only screen and (max-width: 992px) {
				margin-top: 10px;
			}
			& > .firstBTN {
				width: 200px;
				height: 60px;
				border-radius: 10px;
				font-size: 16px;
				font-weight: 600;
			}
			& > .secondBTN {
				font-size: 16px;
				font-weight: 600;
				width: 200px;
				height: 60px;
				border-width: 2px;
				border-radius: 10px;
			}
		}
	}
}

.updateDialogStyle {
	.body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 20px;
		height: 100%;
		text-align: center;
		.title {
			@include font(24px, 600, #000000);
		}
		.details {
			@include font(14px, 400, #000000);
		}
		.closeBtn {
			width: 201px;
			height: 60px;
			background: #542ef9 0% 0% no-repeat padding-box;
			border-radius: 10px;
			@include font(16px, 500, #ffffff);
			margin-top: auto;
		}
	}
}

.personalDetails_modal_container {
	// padding: 56px 30px 30px 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 30px;

	.actions-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 5px;
		margin-top: 15px;
		span {
			text-decoration: underline;
			cursor: pointer;
		}
		span.delete_img_text {
			color: #ff422f;
		}
	}
}

.personalDetails_Content_Img {
	position: relative;
	width: 132px;
	height: 132px;
	cursor: pointer;
	background: #e3e3e3;
	border: 3px solid #ffffff;
	border-radius: 50%;
	margin: 0px auto;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 0;
	img {
		width: 132px !important;
		height: 132px !important;
	}
	.avatar_txt {
		text-align: center;
		font: normal normal normal 12px/28px Poppins;
		color: #a2a2a2;
	}
	.avatar_icon {
		border-radius: 50%;
		object-fit: cover;
	}
	.edit_avatar_icon {
		position: absolute;
		top: 85px;
		inset-inline-end: -5px;
		background: #e3e3e3;
		border: 3px solid #ffffff;
		border-radius: 50%;
		width: 50px;
		height: 50px;
		display: flex;
		padding: 14px;
		svg {
			flex: 1;
			width: 18px;
			height: auto;
		}
	}
}

.k-dialog-wrapper {
	&.change_avatar-dialog,
	&.change_password-dialog {
		.k-dialog {
			width: 488px;
			min-height: 451px;
			.k-dialog-content {
				padding: 3rem;
				padding-top: 10px !important;
				padding-bottom: 30px !important;
				display: flex;
				flex-direction: column;
				gap: 20px;
				align-items: center;
				@include font();
				.photos_Form_Button {
					text-align: center;
					font: normal normal 500 12px/17px Poppins;
					color: #8d8d8d;

					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 20px;

					.upload_text {
						cursor: pointer;
						text-decoration: underline;
					}
					.delete_img_text {
						text-align: left;
						text-decoration: underline;
						font: normal normal 500 12px/17px Poppins;
						color: #ff422f;
						cursor: pointer;
					}
				}
				.dialog_title {
					padding: 0px 35px;
					@include font(20px, 500, #171717);
					width: 100%;
					margin-bottom: 10px;
					font-weight: bold;
				}
				.input_class {
					display: flex;
					flex-direction: column;
					gap: 4px;
					.labelContainer {
						.label {
							text-align: left;
							font: normal normal 500 12px/25px Poppins;
							color: #2b2f33;
						}
					}
					input {
						height: 46px;
						&.--password {
							-webkit-text-security: disc;
						}
					}
				}
				.custom_button {
					width: 100%;
					height: 46px;
					margin-top: auto;
					margin-bottom: 10px;
				}
			}
		}
	}
	&.change_password-dialog {
		.k-dialog {
			.k-dialog-content {
				.custom_button {
					margin-top: 10px;
				}
			}
			.custom_password_with_toggle {
				position: relative;
				display: flex;
				width: 100%;
				cursor: pointer;
				.password_toggle_icon {
					position: absolute;
					bottom: 5px;
					inset-inline-end: 10px;
				}
			}
			.k-form-error {
				bottom: -20px;
			}
		}
	}
	&.change_avatar-dialog {
		.k-dialog {
			.k-dialog-content {
				.dialog_title {
					margin-bottom: 60px;
				}
				.k-upload {
					border: none;
					background: none;
					.k-dropzone {
						border: none;
						background: none;
						.k-upload-button {
							border: none;
							background: none;
							text-align: center;
							text-decoration: underline;
							font: normal normal 500 12px/17px Poppins;
							color: #8d8d8d;
							background: none;
							border: none;
						}
						.k-dropzone-hint {
							display: none;
						}
					}
				}
			}
		}
	}
}
