.header-container {
	background: $white_color;
	width: 100%;
	position: sticky;
	top: 0;
	z-index: 111;
	border-bottom: 1px solid #e3e3e3;
	.profile_Drawer {
		width: 100%;
		height: 100vh;
		background: $white_color;
		box-shadow: 0px 2px 20px #00000022;
		border-radius: 8px;
		@include flex(center, flex-start);
		flex-direction: column;
		padding: 15px 10px;
		position: fixed;
		top: 0;
		bottom: 0;
		overflow: auto;
		border-radius: 0;
		z-index: 4444;
		left: -100%;
		transition: all ease-in-out 0.4s;
		&.active {
			transition: all ease-in-out 0.4s;
			left: 0;
		}
		& > .profile_Drawer_Item {
			position: relative;
			@include flex(center, flex-start);
			height: 62px;
			width: 100%;
			column-gap: 16px;
			padding: 0px 18px;
			@include font(14px, 500, #171717);
			border-bottom: 1px solid #e3e3e3;
			cursor: pointer;
			& > svg {
				width: 25px;
				height: 25px;
				& path {
					stroke: #8d8d8d;
				}
			}
			& > .k-icon {
				@include font(24px, 500, #adadad);
				position: absolute;
				right: 0px;
				&:lang(ar) {
					right: auto;
					left: 0px;
					&.k-i-chevron-right {
						transform: scale(-1);
					}
				}
			}
			& > :last-of-type {
				border-bottom: 0px;
			}
		}
		& > .profile_Drawer_Item_Selected {
			color: $white_color;
			background-color: #542ef9;
			border-radius: 8px;
			height: 62px;
			& > .k-icon {
				@include font(24px, 500, $white_color);
				display: none;
			}
			& > svg {
				& path {
					stroke: $white_color;
				}
			}
		}
		& > .bottom_Section {
			height: 150px;
			width: 100%;
			padding: 0px 22px 37px;
			@include flex(flex-start, flex-end);
			flex-direction: column;
			gap: 21px;
			position: absolute;
			bottom: 10px;
			& > span:first-of-type {
				@include font(14px, 400, #8d8d8d);
				text-decoration: underline;
				cursor: pointer;
			}
			& > span:nth-of-type(2) {
				@include font(14px, 400, #ff6a6a);
				cursor: pointer;
			}
		}
	}

	.header-sub-container {
		@include flex(center, space-between);
		width: 100%;
		height: 70px;
		border-bottom: 1px solid #f2f2f0;
		background: $white_color;
		// max-width: 1480px;
		margin: auto;
		// @media (min-width: 1440px) and (max-width: 1920px), (max-width: 1440px), (max-height: 768px) {
		//     max-width: 1200px;
		//     // height: 75px;
		// }
		// @media (max-width: 1280px), (max-height: 800px) {
		//     max-width: 1050px;
		//     // height: 60px;
		// }
		@media (max-width: 992px) {
			height: 56px;
		}
		.Header_Left {
			@include flex(center, center);
			padding: 0px 20px;
			padding-inline-start: 0;
			column-gap: 33px;
			@media (min-width: 1440px) and (max-width: 1920px), (max-width: 1440px), (max-height: 768px) {
				column-gap: 31px;
			}
			@media (max-width: 992px) {
				padding-inline-end: 0;
			}
			@media (max-width: 425px) {
				padding: 0px 10px;
			}
			.Header_Brand {
				cursor: pointer;
				width: 180px;
				height: 40px;
				position: relative;
				a {
					width: 100%;
					height: 100%;
					display: flex;
					svg,
					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
				@media (min-width: 1440px) and (max-width: 1920px), (max-width: 1440px), (max-height: 768px) {
					width: 155px;
					height: 35px;
				}
				@media (max-width: 1280px), (max-height: 800px) {
					width: 135px;
					height: 31px;
				}
				@media (max-width: 881px) {
					width: 113px;
					height: 26px;
				}
			}
			.moreTravel_trigger_holder {
				padding: 11px;
				.moreTravel_trigger {
					@include font(18px, 500, #2b2f33);
					border: 0;
					padding: 0 10px;
					box-shadow: none;
					@media (max-width: 881px) {
						display: none;
					}
					@media (min-width: 1440px) and (max-width: 1920px), (max-width: 1440px), (max-height: 768px) {
						font-size: 16px;
					}
					@media (max-width: 1280px), (max-height: 800px) {
						font-size: 14px;
					}
					svg {
						font-size: 12px;
						fill: #a7a7a7;
					}
				}
			}
		}
		.Header_Right {
			@include flex(center, center);
			column-gap: 42px;
			padding: 0px 20px;
			padding-inline-end: 0;
			margin-inline-start: auto;
			@media (min-width: 1440px) and (max-width: 1920px), (max-width: 1440px), (max-height: 768px) {
				column-gap: 24px;
			}
			@media (max-width: 1280px), (max-height: 800px) {
				column-gap: 24px;
			}
			@media (max-width: 992px) {
				margin-inline-start: initial;
				padding-inline-start: 0;
				// padding-inline-end: 10px;
			}
			@media (max-width: 425px) {
				padding-inline-start: 0;
				// padding-inline-end: 10px;
			}

			.header-visitksa-explore {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				padding: 12px 8px 12px 12px;
				width: 255px;
				height: 40px;
				background: #ffffff;
				border: 1px solid #d6d9d3;
				border-radius: 20px;
				cursor: pointer;

				@media only screen and (max-width: 768px) {
					width: auto;
					padding: 0;
					height: auto;
					border: none;
					p {
						display: none;
					}
					span {
						font-size: 20px;
					}
				}

				p {
					font-weight: 400;
					font-size: 14px;
					line-height: 150%;
					color: #595959;
				}
			}
			.profile_trigger {
				height: auto;
				border: 0 !important;
				box-shadow: none;
				padding-inline: 0;
				@media (max-width: 992px) {
				}
				@media (max-width: 425px) {
					padding: 0;
					.ant-avatar-image {
						margin-inline-end: 0 !important;
					}
					.ant-space {
						gap: 3px !important;
					}
				}
				.profile_name {
					@include font(18px, 500, $black-color);
					cursor: pointer;
					@media (min-width: 1440px) and (max-width: 1920px), (max-width: 1440px), (max-height: 768px) {
						font-size: 12px;
					}
					@media (max-width: 1280px), (max-height: 800px) {
						font-size: 14px;
					}
				}
			}
			.Header_Right_Options {
				@include flex(center, center);
				column-gap: 31px;
				@media only screen and (max-width: 992px) {
					display: none;
				}
				.Header_Right_ListService {
					width: fit-content;
				}
				// .Header_Right_ListService {
				//     @include font(18px, 500, $black-color);
				//     width: auto;
				//     height: 59px;
				//     border: 2px solid $black-color;
				//     border-radius: 12px;
				//     background: $white_color;
				//     @media (min-width: 1440px) and (max-width: 1920px), (max-width: 1440px), (max-height: 768px) {
				//         @include font(12px, 500, $black-color);
				//         width: auto;
				//         height: 45px;
				//         border: 1px solid $black-color;
				//         border-radius: 8px;
				//     }
				//     @media (max-width: 1280px), (max-height: 800px) {
				//         @include font(14px, 500, $black-color);
				//         width: auto;
				//         height: 39px;
				//         border: 2px solid $black-color;
				//     }
				//     @media (max-width: 881px) {
				//         display: none;
				//     }
				// }
				.header_preference {
					@include flex(center, center);
					@include font(14px, 500, gray);
					column-gap: 16px;
					& > div {
						height: 30px;
					}
					.currency-lang-btn {
						height: 40px;
						display: flex;
						align-items: center;
						border: 0;
						box-shadow: none;
						font-weight: 500;
						font-size: 14px;
						color: #595959;
					}
					.currencyToggler {
						display: flex;
						align-items: center;
						background: #f7f7f7;
						border: 1px solid #efefef;
						border-radius: 6px;
						padding: 0px 7px;
						cursor: pointer;
					}
					@media only screen and (max-width: 992px) {
						display: none;
					}
					@media (min-width: 1440px) and (max-width: 1920px), (max-width: 1440px), (max-height: 768px) {
						column-gap: 12px;
					}
					@media (max-width: 1280px), (max-height: 800px) {
						column-gap: 11px;
					}
				}
			}
			.header_login_button {
				width: fit-content;
				@media only screen and (max-width: 992px) {
					height: 32px;
					font-size: 14px;
				}
			}
		}
	}
	.home-res-menu {
		position: fixed;
		background-color: white;
		z-index: 222;
		inset: 0;
		min-height: 100vh;
		overflow: auto;
		padding: 25px 20px;
		translate: -100%;
		transition: 0.3s;
		&.active {
			translate: 0%;
		}
		.preferences_section {
			display: flex;
			// flex-direction: column;
			align-items: flex-start;
			justify-content: space-between;
			gap: 25px;
			.currency-lang-btn,
			.LangButton {
				background: #f7f7f7;
				border: 1px solid #efefef;
				border-radius: 6px;
				padding: 0px 7px;
				cursor: pointer;
				flex: 1;
				height: 40px;
				flex: 1;
				font-weight: 500;
				font-size: 14px;
			}
			.currencyToggler {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			.LangButton {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 100%;
				width: 100%;
				gap: 15px;
				z-index: 1;
				.ant-space-item {
					display: flex;
					align-items: center;
					justify-content: center;
					// width: 100%;
					svg {
						position: relative;
						// width: 40px;
						height: 40px;
						border-radius: 12px;
					}
					.anticon-down {
						align-items: center;
						svg {
							width: 10px;
						}
					}
				}
			}
		}
		.menu-conetnt-holder {
			display: flex;
			flex-direction: column;
			gap: 30px;
			.res-menu-header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				@include font(18px, 600, #171717);

				.res-menu-header-logo {
					position: relative;
					width: 113px;
					height: 26px;
				}
				.close_button {
					border: none;
					background: none;
					box-shadow: none;
					span {
						&::before {
							font-size: 2rem;
						}
					}
				}
			}

			.menu-section-list {
				display: flex;
				flex-direction: column;
				gap: 40px;
				.tabs-list-holder {
					display: flex;
					flex-direction: column;
					gap: 25px;
					padding-top: 25px;
					// border-top: 1px solid #e5e5e5;
					// border-bottom: 1px solid #e5e5e5;

					h3 {
						@include font(16px, 600, #171717);
					}
					ul {
						display: flex;
						flex-direction: column;
						gap: 30px;
						li {
							display: flex;
							a {
								display: flex;
								align-items: center;
								gap: 15px;
								@include font(18px, 400, #000000);
								text-decoration: none;
								svg {
									width: 25px;
									height: 25px;
									path {
										fill: #000000;
										opacity: 0.25;
										&#Path_4291,
										&#Path_4293,
										&#Path_4294 {
											fill: none;
											opacity: 1;
										}
									}
								}
								span {
									@include font(10px, 800, #ffffff);
									display: flex;
									align-items: center;
									justify-content: center;
									width: 47px;
									height: 25px;
									background: #ff0045 0% 0% no-repeat padding-box;
									border-radius: 13px;
								}
								&:hover {
									color: $primary_color;
									& > svg {
										path {
											fill: $primary_color;
											opacity: 1;
										}
									}
								}
							}
						}
					}
				}
				.services-list-holder {
					ul {
						display: flex;
						flex-direction: column;
						gap: 15px;
						// padding-bottom: 25px;
						// border-bottom: 1px solid #e5e5e5;
						li {
							display: flex;
							a {
								display: flex;
								align-items: center;
								gap: 15px;
								@include font(16px, 400, #171717);
								text-decoration: none;
								&:hover {
									color: $primary_color;
								}
							}
						}
					}
				}
				.contact-holder {
					display: flex;
					flex-direction: column;
					gap: 15px;
					.contact-details-container {
						display: flex;
						align-items: center;
						gap: 10px;
						& > svg {
							width: 28px;
							height: 28px;
							g {
								path {
									fill: #542ef9;
									stroke: none;
								}
							}
						}
						.contact-details {
							display: flex;
							flex-direction: column;
							gap: 10px;
							.phone {
								@include font(14px, 600, #171717);
							}
							.email {
								@include font(14px, 500, #542ef9);
							}
						}
					}
				}
				.partner_button {
					@include font(16px, 500, #000000);
					width: 100%;
					padding: 20px;
					border: 2px solid #000000;
					border-radius: 12px;
					background: none;
					height: auto;
				}
			}
		}
	}
}
.menu-bars-holder {
	display: none;
	font-size: 25px;
	@media only screen and (max-width: 992px) {
		display: block;
		margin-inline-end: 30px;
	}
}
.currencyModalStyle {
	max-width: 60vw;
	width: 100% !important;
	@media (max-width: 768px) {
		max-width: 97vw;
	}
	.ant-modal-content {
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 6px 36px #00000014;
		border-radius: 16px;
		opacity: 1;
		padding: 50px 40px;
		padding-top: 20px;
		@media (max-width: 768px) {
			padding: 50px 20px;
		}
		.ant-modal-close {
			top: 25px;
		}
		.ant-modal-header {
			// border: 0;
			// padding: 16px 0;
			.ant-modal-title {
				@include font(18px, 600, #2b2f33);
				margin: 0;
				text-align: start;
			}
		}
		.ant-modal-body {
			padding: 0;
			margin: 40px 0 0;
			.modal_content {
				display: flex;
				flex-direction: column;
				gap: 40px;
				.currencies_list {
					display: flex;
					flex-direction: column;
					gap: 20px;
					.sub_title {
						@include font(15px, 600, #2b2f33);
						text-align: left;
						padding: 0 10px;
						&:lang(ar) {
							text-align: right;
						}
					}
					.currencies {
						// @include flex(center, flex-start);
						display: grid;
						grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
						gap: 10px;
						@media (max-width: 520px) {
							grid-template-columns: 1fr 1fr;
						}
						.currency_item {
							@include flex(flex-start, space-between);
							flex-direction: column;
							@include font(14px, 400, #000);
							padding: 8px 14px;
							border-radius: 6px;
							// width: 188px;
							max-width: 300px;
							min-height: 65px;
							text-align: left;
							border: 0;
							background: none;
							cursor: pointer;
							border: 1px solid #f3f3f3;
							@media (max-width: 768px) {
								max-width: 97vw;
							}
							&:lan(ar) {
								text-align: right;
							}
							&:hover {
								background: #f4f4f4 0% 0% no-repeat padding-box;
							}
							&.active {
								color: #542ef9;
								background: #f4f4f4 0% 0% no-repeat padding-box;
							}
							.currency_abbr {
								@include flex(center, space-between);
								width: 100%;
								height: 26px;
							}
						}
					}
				}
			}
		}
	}
}
.close-row-holder {
	display: none;
	width: 100%;
	justify-content: flex-end;
	margin-bottom: 20px;
	button {
		background-color: rgba(235, 42, 20, 0.8);
		color: #fff;
	}
	@media only screen and (max-width: 992px) {
		display: flex;
		// display: none;
	}
}

.LangButton {
	// background: #f7f7f7;
	// border: 1px solid #efefef;
	border-radius: 6px;
	padding: 0px 7px;
	cursor: pointer;
	.AuthHeader_Lang_Holder {
		width: 100%;
		height: 100%;
		justify-content: center;
		color: #595959;
		.ant-space-item {
			display: flex;
			align-items: center;
			svg {
				// position: relative;
				// width: 30px;
				// height: 25px;
				// border-radius: 6px;
			}
			.anticon-down {
				align-items: center;
				svg {
					width: 10px;
				}
			}
		}
	}
}

.lang_dropdown {
	.ant-dropdown-menu {
		padding: 10px;
		min-width: 150px;
		border-radius: 10px;
		.ant-dropdown-menu-item {
			@include font(12px, 600, #000);
			display: flex;
			align-items: center;
			justify-content: flex-start;
			column-gap: 10px;
			&:nth-of-type(1) {
				pointer-events: none;
			}
			&:hover {
				color: coral;
			}
			svg {
				width: 30px;
				height: 25px;
			}
		}
	}
}
.more_dropdown {
	.ant-dropdown-menu {
		@include flex(flex-start, center);
		padding: 32px 37px;
		max-width: 992px;
		flex-direction: column;
		row-gap: 30px;
		column-gap: 40px;
		border-radius: 12px;
		.list_container {
			@include flex(flex-start, space-between);
			flex-direction: column;
			row-gap: 30px;
			.ant-dropdown-menu-item {
				background: none;
				padding: 0;
				.ant-dropdown-menu-title-content {
					@include flex(center, center);
					gap: 10px;
					a {
						@include flex(center, space-between);
						@include font(16px, 500, $black-color);
						gap: 10px;
						svg {
							path {
								fill: #a7a7a7;
							}
						}
						&:hover {
							color: $primary_color;
							& > svg {
								path {
									fill: $primary_color;
								}
							}
							& > span {
								color: $primary_color;
							}
						}
					}
					.soon_badge {
						@include flex(center, center);
						margin: 0px;
						@include font(12px, 600, $white_color);
						padding: 8px;
						width: 59px;
						background: #ff0045;
						border-radius: 30px;
					}
				}
			}
		}
		hr {
			transform: rotate(180deg);
			background-color: #e3e3e3;
			width: 1px;
			height: 186px;
			border: unset;
			margin: 0 20px;
		}
	}
}
.profile_dropdown {
	.ant-dropdown-menu {
		padding: 10px;
		background-color: $white_color;
		border: 1px solid #eaeaea;
		border-radius: 10px;
		min-width: 200px;
		.ant-dropdown-menu-item {
			border-radius: 10px;
			padding: 10px;
			font-size: 15px;
			font-weight: 500;
			transition: all 0.15s;
			gap: 8px;
			&.sub_item {
				padding: 5px 10px;
				font-size: 14px;
				font-weight: 400;
				color: #8d8d8d;
				&:hover {
					background: none;
					& > span {
						color: #8d8d8d;
					}
				}
				&.ant-dropdown-menu-item-danger {
					color: #ff4d4f;
					&:hover {
						background: none;
						& > span {
							color: #ff4d4f;
						}
					}
				}
			}
			// &:hover {
			//     background: #542ef9;
			//     & > span {
			//         color: $white_color;
			//     }
			//     & > svg {
			//         path {
			//             stroke: $white_color;
			//         }
			//     }
			// }
			& > svg {
				width: 24px;
				height: 24px;
				stroke-width: 2px;
				margin: 0;
			}
			& > .ant-dropdown-menu-title-content {
				& > a {
					transition: all 0.15s;
				}
			}
		}
		.ant-divider {
			margin: 12px 0;
		}
	}
}

.header-drawer {
	.ant-menu {
		padding-top: 20px;

		.ant-menu-item {
			display: flex;
			align-items: center;

			.ant-menu-item-icon {
				svg {
					path {
						fill: rgb(255, 255, 255);
						stroke: rgb(141, 141, 141);
					}
				}
			}
		}
	}

	.ant-avatar-image {
		height: 56px;
		width: 56px;
	}

	.user-data {
		display: flex;
		// flex-direction: column;
		// align-items: flex-start;
		align-items: center;
		justify-content: flex-start;
		gap: 15px;

		.profile_name {
			margin: 0;
		}
	}
}

// .ant-drawer-content-wrapper {
// 	width: 90% !important;
// }
.drawer-header {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	// border-bottom: 1px solid #e5e5e5;
	padding: 0 20px 0 20px;

	.profile_name {
		margin-inline-start: 10px;
		font-size: 16px;
		font-weight: 500;
	}
}
.header-visitksa-drawer {
	.ant-drawer-content-wrapper {
		height: auto !important;
		width: 100% !important;
	}
	.ant-drawer-body {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
