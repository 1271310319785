@mixin packageType {
	.packageTypeContainer {
		.packageTypeBoxes {
			margin-top: 31px;
			@include flex(flex-start, center);
			row-gap: 26px;
			column-gap: 26px;
			max-width: 769px;
			transition: all ease-in-out 0.2s;
			.ant-checkbox-wrapper + .ant-checkbox-wrapper {
				margin: 0;
			}
			.ant-checkbox {
				display: none;
			}
			.ant-checkbox + span {
				padding: 0;
			}
			.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
				.packageTypeBox {
					// background: $primary_color !important;
					// svg {
					//     path {
					//         stroke: $white_color;
					//         fill: $white_color;
					//     }
					// }
					// span {
					//     @include font(15px, 500, $white_color);
					// }
					// p {
					//     @include font(12px, 400, $white_color);
					// }
					// border-color: $primary_color !important;
					border-width: 2px;
					transition: all ease-in-out 0.2s;
					img {
						mix-blend-mode: luminosity;
					}
				}
			}
			.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
				cursor: not-allowed;
				.packageTypeBox {
					cursor: not-allowed;
				}
			}
			.packageTypeBox {
				&:hover {
					background: #f5f5f5;
				}
				cursor: pointer;
				width: 239px;
				height: 236px;
				border: 1px solid #c9c9c9;
				border-radius: 12px;
				@include flex(center, center);
				flex-direction: column;
				padding: 20px 15px;
				text-align: center;
				flex-wrap: unset;
				svg {
					width: 55px;
					height: 45px;
					min-height: 45px;
				}
				span {
					@include font(15px, 500, $black-color);
					margin: 5px 0;
				}
				p {
					margin: 8px auto 0px;
					@include font(12px, 400, #8d8d8d);
				}
			}
			// .packageTypeBox_active {
			//     background: $primary_color !important;
			//     svg {
			//         path {
			//             stroke: $white_color;
			//             fill: $white_color;
			//         }
			//     }
			//     span {
			//         @include font(15px, 500, $white_color);
			//     }
			//     p {
			//         @include font(12px, 400, $white_color);
			//     }
			// }
		}
	}
	@media (max-width: 992px) {
		.packageTypeBoxes {
			display: grid !important;
			grid-template-columns: 1fr 1fr;
			gap: 14px !important;
			align-items: stretch !important;
			> .ant-checkbox-wrapper {
				height: 100% !important;
			}
			.packageTypeBox {
				width: 100% !important;
				height: 100% !important;
				min-height: 35vh;
			}
			.ant-checkbox + span {
				height: 100% !important;
				width: 100%;
			}
		}
	}
}
