@mixin availability {
	.availabilityContainer {
		.availability-type-holder {
			margin-top: 30px;
			span.ant-radio + * {
				font-size: 16px;
				font-weight: 500;
				color: #000;
				user-select: none;
			}
			// .ant-radio.ant-radio-checked {
			//     + span {
			//         color: #5143f8;
			//         span {
			//             color: #5143f8 !important;
			//         }
			//     }
			// }
		}
		.input-holder {
			margin-bottom: 26px;
		}
		.availability_Form_Counter_title {
			display: flex;
			align-items: center;
			column-gap: 5px;
			height: 100%;
			& > span {
				@include font(16px, 400, $vibrarnt-black);
				user-select: none;
			}
			& > p {
				@include font(16px, 400, #ff851c);
				margin: 0px;
				user-select: none;
			}
		}

		.ticket_Counter_Function {
			margin-bottom: 0;
			.ant-form-item-control-input-content {
				text-align: right;
				&:lang(ar) {
					text-align: left;
				}
			}
			.ant-input-number-group {
				width: fit-content;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 14px;
				.ant-input-number {
					background-color: transparent;
				}

				.ant-input-number-group-addon {
					width: fit-content;
					background-color: transparent;
					border: 0;
					cursor: pointer;
					padding: 0;
					.anticon {
						// color: #542ef9;
						font-size: 48px;
						&[disabled] {
							pointer-events: none;
							opacity: 0.3;
						}
					}
				}
			}
			.ant-input-number-input-wrap {
				user-select: none;
			}
			.ant-input-number-input {
				width: 100px;
				height: 38px;
				padding: 0;
				text-align: center;
				user-select: none;
				font-size: 20px;
				font-weight: 500;
				color: #2b2f33;
			}
		}
		@media only screen and (max-width: 992px) {
			padding: 16px;
			height: auto;
		}
	}
}
