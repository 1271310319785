@mixin font($fontSize: 16px, $fontWeight: 500, $color: $white_color) {
    font-size: $fontSize !important;
    font-weight: $fontWeight;
    color: $color;
}

@mixin flex($align: center, $justify: center) {
    display: flex;
    align-items: $align;
    justify-content: $justify;
    flex-wrap: wrap;
}
@mixin RegisterBox_Ul_Common() {
    font-size: 12px;
    font-weight: 500;
    margin: 6px 0px;
}
