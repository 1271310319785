.Carousal_Swiper_SecondSection {
	height: 172px;
	width: 100%;
	background: $white_color;
	box-shadow: 0px 13px 46px #00000019;
	border-radius: 0px 22px 22px 22px;
	@include flex(center, center);
	padding: 40px;
	column-gap: 16px;
	row-gap: 16px;
	.Carousal_Swiper_SecondSection_First {
		height: 100%;
		border-radius: 12px;
		position: relative;
		@include flex(center, center);
		column-gap: 20px;
		.Carousal_Swiper_SecondSection_First_Swipper {
			z-index: 2;
			width: 43px;
			height: 43px;
			background: $white_color;
			box-shadow: 0px 2px 16px #00000017;
			border: 1px solid #eaeaea;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			border-radius: 100px;
			cursor: pointer;
			span {
				width: 20px !important;
				left: 50% !important;
				height: 13px !important;
				top: 50% !important;
				transform: translate(-50%, -50%);
			}
			&::before {
				top: -7px !important;
				width: 19px;
				height: 6px;
				left: 21px !important;
				content: "";
				position: absolute;
				z-index: 1;
				transform: translate(-50%, -50%);
				background: white;
				border-top-left-radius: 4px;
				border-top-right-radius: 6px;
				border: 0px;
				padding: 1px;
				box-sizing: content-box;
			}
			&::after {
				width: 19px;
				height: 6px;
				left: 21px !important;
				top: 46px !important;
				content: "";
				position: absolute;
				z-index: 1;
				transform: translate(-50%, -50%);
				background: white;
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 6px;
				border: 0px;
				padding: 1px;
				box-sizing: content-box;
			}
		}
		&::before {
			width: 55px;
			height: 55px;
			left: 50% !important;
			top: 50% !important;
			content: "";
			position: absolute;
			box-sizing: content-box;
			z-index: 1;
			transform: translate(-50%, -50%);
			border-radius: 100px;
			background: $white_color;
			border: 1px solid $lightt-grey;
		}
		.Carousal_Swiper_SecondSection_First_Box {
			border: 1px solid $lightt-grey;
			width: 220px;
			height: 100%;
			border-radius: 15px;
			flex-direction: column;
			@include flex(flex-start, center);
			row-gap: 7px;
			padding: 0px 26px;
			cursor: pointer;
			p {
				margin: 0px;
				@include font(14px, 500, #aaaaaa);
			}
			span {
				@include font(18px, 500, #2b2f33);
			}
		}
	}
	.Carousal_Swiper_SecondSection_Middle {
		width: 397px;
		height: 100%;
		border: 1px solid $lightt-grey;
		border-radius: 12px;
		@include flex(center, space-between);
		padding: 0px 22px;
		position: relative;
		.Carousal_Swiper_SecondSection_Separator {
			border-left: 1px dashed #adadad;
			position: absolute;
			left: 50%;
			margin-left: -3px;
			top: 50%;
			transform: translateY(-50%);
			height: 53px;
		}
		.Carousal_Swiper_SecondSection_Calender {
			@include flex(center, center);
			column-gap: 17px;
			cursor: pointer;
			.Carousal_Swiper_SecondSection_Calender_Icon {
				position: relative;
				width: 23px;
				height: 25px;
				& > svg {
					width: 23px !important;
					height: 25px !important;
				}
			}
			.Carousal_Swiper_SecondSection_Calender_Text {
				flex-direction: column;
				@include flex(flex-start, center);
				row-gap: 7px;
				p {
					margin: 0px;
					@include font(14px, 500, #aaaaaa);
				}
				span {
					@include font(18px, 500, #2b2f33);
				}
			}
		}
	}
	.Carousal_Swiper_SecondSection_Last {
		height: 100%;
		row-gap: 10px;
		column-gap: 32px;
		display: flex;
		align-items: center;
		button {
			width: 206px;
			height: 100%;
			background: transparent linear-gradient(59deg, #542ef9 0%, #943efb 100%) 0% 0% no-repeat padding-box;
			border-radius: 12px;
			.k-button-icon {
				@include font(30px, bold, $white_color);
				padding: 0px 20px;
			}
			& > span {
				@include font(18px, bold, $white_color);
			}
		}
		.Carousal_Swiper_SecondSection_Unit {
			width: 235px;
			height: 100%;
			border: 1px solid $lightt-grey;
			border-radius: 12px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			column-gap: 18px;
			padding: 0px 13px;
			cursor: pointer;
			.Carousal_Swiper_SecondSection_Unit_Text {
				flex-direction: column;
				@include flex(flex-start, center);
				row-gap: 7px;
				cursor: pointer;
				p {
					margin: 0px;
					@include font(14px, 500, #aaaaaa);
				}
				span {
					@include font(18px, 500, #2b2f33);
				}
			}
			.Carousal_Swiper_SecondSection_Unit_Icon {
				width: 32px;
				height: 27px;
				position: relative;
				span {
					width: 32px !important;
					height: 27px !important;
				}
			}
		}
	}
	&.carousal-swiper-Packages-wrapper {
		width: 100%;
		height: auto;
		// max-width: 80vw;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 20px;
		.Carousal_Swiper_SecondSection_Middle {
			width: 100% !important;
			height: 73px !important;
			flex-wrap: nowrap;
			@media only screen and (max-width: 992px) {
				height: 63px !important;
			}
		}
		.Carousal_Swiper_SecondSection_Last {
			.k-button {
				width: 100% !important;
				height: 73px !important;
				background: transparent linear-gradient(248deg, #363bfa 0%, #8457f4 100%) 0% 0% no-repeat padding-box;
			}
		}
		@media only screen and (max-width: 1050px) {
			grid-template-columns: 1fr;
			gap: 10px;
			border-radius: 12px !important;
			padding: 20px;
			.Carousal_Swiper_SecondSection_Last {
				margin-top: 20px;
			}
		}
		@media only screen and (max-width: 992px) {
			width: 95%;
			height: auto !important;
			margin: auto;
		}
	}
}
.hero_carousel {
	position: relative;
	min-height: 300px;
	max-height: 50vh;
	height: 50vh;
	@media only screen and (max-width: 1024px) {
		max-height: 45vh;
		height: 45vh;
	}
	.slick-arrow {
		font-size: 24px;
		color: #000;
		background: #ededed;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: flex !important;
		align-items: center;
		justify-content: center;
		z-index: 2;
		inset-inline: unset;
		&:hover,
		&:focus {
			color: #000;
			background: #ededed;
		}
		&.slick-prev {
			inset-inline-start: 40px;
		}
		&.slick-next {
			inset-inline-end: 40px;
		}
		@media (max-width: 1024px) {
			display: none !important;
		}
	}
	.slick-list {
		.slick-track {
			.slick-slide {
				.heroCarousel_item {
					position: relative;
					display: flex !important;
					justify-content: flex-start;
					align-items: center;
					min-height: 300px;
					max-height: 50vh;
					height: 50vh;
					@media only screen and (max-width: 1024px) {
						max-height: 45vh;
						height: 45vh;
					}
					// @media screen and (min-height: 800px) {
					//     height: 65vh;
					//     max-height: 65vh;
					// }
					.carousal-content-holder {
						// padding-bottom: 20px;
						@media (max-width: 992px) {
							// display: flex;
							// flex-direction: column;
							// h2,
							// .text_container {
							//     padding: 0 !important;
							// }
						}
						h2 {
							font-size: 50px;
							font-weight: 600;
							color: #fff;
							@media (max-width: 992px) {
								// padding: 3rem 3rem 6rem;
								font-size: 34px;
							}
							@media (max-width: 768px) {
								font-size: 24px;
								padding: 1rem 0;
							}
						}
					}
					.text_container {
						width: 100%;
						position: absolute;
						height: 100%;
						display: flex;
						align-items: center;
						z-index: 2;
						.heroCarousel_text {
							font-size: 28px;
							font-weight: 300;
							line-height: 50px;
							color: #fff;
							max-width: 60%;
							margin: 18px 0;
							margin-top: 0;
							@media (max-width: 992px) {
								max-width: 80%;
								font-size: 24px;
								line-height: normal;
							}
							@media (max-width: 768px) {
								font-size: 16px;
							}
						}
					}

					.ant-image {
						width: 100%;
						height: 100%;
						min-height: 300px;
						img {
							height: 100%;
							min-height: 300px;
							object-fit: cover;
							animation: zoom-in-zoom-out 35s ease-out infinite;
						}
					}
				}
			}
		}
	}
}
@keyframes zoom-in-zoom-out {
	0% {
		transform: scale(1, 1);
	}
	50% {
		transform: scale(1.1, 1.2);
	}
	100% {
		transform: scale(1, 1);
	}
}
