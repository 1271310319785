@mixin sideBar_header_badge {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 37px;
	flex-wrap: wrap;
	border: 1px solid rgba(0, 0, 0, 0.08);
	border-top: unset;
	border-bottom: unset;
	transition: all 200ms ease 0s;
}

.arrow {
	// position: absolute !important;
	// right: -5px;
	color: #bfbfbf;
	font-size: 20px !important;
}

.sideBar_header {
	display: flex;
	width: 100%;
	background: $white_color;
	position: fixed;
	top: 0px;
	z-index: 101;
}

.sideBar_header_badge_icon {
	cursor: pointer;
	margin-top: 15px;
}

.sideBar_header_badge_shown {
	width: 291px;
	@include sideBar_header_badge;
	background: $white_color;
	.sideBar_header_badge_title {
		height: 36.5px;
		transition: all 2000ms ease 0s;
	}
}

.sideBar_header_badge_hidden {
	width: 123px;
	@include sideBar_header_badge;
	background: $white_color;
	& > span {
		display: none !important;
	}
	.sideBar_header_badge_title {
		display: none !important;
		transition: all 2000ms ease 0s;
	}
}

.sideBar_header_badge_Mobile {
	display: none;
}

.ant-layout,
.ant-drawer {
	height: 100%;
	.trigger {
		font-size: 22px;
		margin-inline-end: 10px;
	}
	.menu-container {
		padding: 25px 0px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		ul {
			border: 0 !important;
		}
	}
	.collapsed {
		ul {
			.ant-menu-title-content {
				visibility: hidden;
			}
		}
		// display: none;
	}
	.sideBar_header_badge_title {
		// margin-bottom: 50px;
		object-fit: contain;
	}
}

.ant-drawer .ant-drawer-body {
	padding: 1rem 0px;
}
