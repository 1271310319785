@import "./startTravelShop.scss";
@import "./inspiration.scss";
@import "./recommendStays.scss";
@import "./popularPackage.scss";
@import "./carousal.scss";
@import "./planTrip.scss";
@import "./Warn.scss";

.website-wrapper {
	background: $white_color;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	@include Inspiration;
	@include StartTravelShop;
	@include RecommendStatus;
	@include PopularPackage;
	@include PlanTrip;
	@include Warn;
}
