.k-popup.popup-content.k-slide-down-enter-active {
    .packageItemPopUp {
        .k-i-more-horizontal {
            display: none;
        }
    }
}

@mixin ProfilePopUp {
    .profile_container {
        padding: 10px 7px;
        background-color: $white_color;
        box-shadow: 0px 0px 13px #00000019;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        width: 226px;
        height: auto;
        & > .uppersection {
            height: auto;
            width: 100%;
            @include flex(flex-start, flex-start);
            & > .uppersctionItem {
                padding: 0px 11px;
                height: 42px;
                width: 100%;
                border-radius: 10px;
                @include flex(center, flex-start);
                column-gap: 11.5px;
                cursor: pointer;
                svg {
                    max-width: 24px;
                }
                & > span {
                    @include font(15px, 500, $black-color);
                }
                &:hover {
                    background: #542ef9;
                    & > span {
                        @include font(15px, 500, $white_color);
                    }
                    & > svg {
                        path {
                            stroke: $white_color;
                        }
                    }
                }
            }
        }
        & > hr {
            border: unset;
            border-top: 1px solid #e3e3e3;
            margin-block-start: 13px;
        }
        & > .lowersection {
            padding: 5px 12.5px 15px;
            & > span {
                cursor: pointer;
                width: 100%;
                display: block;
                @include font(14px, 400, #8d8d8d);
                margin-bottom: 6px;
            }
            & > p {
                cursor: pointer;
                display: block;
                width: 100%;
                margin: 0px;
                @include font(14px, 400, #ff6a6a);
            }
        }
    }
}

@mixin PackageItemPopUp {
    .packageItemPopUp {
        width: 275px;
        height: 202px;
        padding: 8px 23px 0px;
        & > .k-icon {
            position: absolute;
            top: 24px;
            right: 15px;
            @include font(24px, 500, #b9b9b9);
            cursor: pointer;
        }
        @include flex(center, flex-start);
        flex-direction: column;
        & > div {
            cursor: pointer;
            height: 62px;
            width: 100%;
            @include flex(center, flex-start);
            column-gap: 7px;
            & > p {
                margin: 0px;
                @include font(16px, 400, #000);
            }
            border-bottom: 1px solid #e3e3e3;
            &:last-child {
                border-bottom: unset;
                & > p {
                    @include font(16px, 400, #ff3434);
                }
            }
        }
        & > .disable-delete {
            & > p {
                color: #a7a7a7 !important;
            }
            & > svg {
                & path {
                    fill: #a7a7a7;
                }
            }
        }
        &.bookingItemPopUp {
            height: auto;
        }
    }
}

.popup-content {
    border-radius: 10px;
    transition-delay: 500ms !important;
    @include ProfilePopUp;
    @include PackageItemPopUp;
    &.popup-zoom-res {
        zoom: 0.7;
    }
}
