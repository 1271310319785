@mixin pricing {
	.remove-field-btn {
		// background: #f7f5ff;
		border-radius: 8px;
		width: 50px;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		// border: 1px solid #c7bbf9;
		svg {
			font-size: 22px;
			color: rgb(207, 33, 16);
		}
		@media (max-width: 640px) {
			// width: 100%;
		}
	}
	.add-field-btn {
		display: flex;
		align-items: center;
		text-decoration: underline;
		font-weight: 500;
		padding: 0;
	}

	.pricingContainer {
		.pricing-discount {
			position: relative;
			@media (max-width: 1280px) {
				background-color: #ebf2fa;
				margin-bottom: 2rem;
			}
			box-shadow: none;
			border: 0 !important;
			.ant-col {
				.ant-form-item {
					margin-bottom: 0.5rem;
				}
			}
			.ant-card-body {
				padding: 1rem;
				border: 0;
				.remove-field-col {
					button {
						margin-top: 2.5rem;
					}

					@media (max-width: 1280px) {
						position: absolute;
						right: 0;
						top: 0;
						button {
							margin-top: 0.5rem;
						}
					}
					min-width: 2rem;
					display: flex;
					align-items: center;
					justify-content: center;
					// margin-right: 0.5rem;
					padding: 0;
				}
			}
		}
		.pricing_Form {
			max-width: 768px;
			margin: 30px 0px;
			background: #f0f0f0;
			border-radius: 8px;
			padding: 30px;
			input[type="number"] {
				-moz-appearance: textfield;
			}
			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
			}
			.custom-color {
				.ant-select-selection-item {
					background: #5143f8;
					color: white;
					border-radius: 8px;
				}
			}

			.extra-label {
				font-size: small;
				margin: 0rem 0.5rem;
			}
			.input-holder .ant-select {
				height: auto;
			}
			.ant-select-selection-item-content {
				display: flex;
				align-items: center;
			}
			.ant-select-selection-item-remove {
				display: flex;
				align-items: center;
				margin-top: 1px;
				margin-left: 0.5rem;
				margin-right: 0.5rem;
			}
			.input-holder .ant-select .ant-select-selector {
				min-height: 45px;
				height: auto !important;
				.anticon-close {
					color: #fff;
				}
			}
		}
	}
	.pricingContainer {
		.cancellation_Form_ant {
			margin-top: 0rem;
			label {
				font-size: 16px !important;
				font-weight: 500;
				color: #171717;
				&::before {
					display: none;
				}
			}
			input {
				height: 40px;
				background: #f2f2f2;
				border-radius: 8px;
				border: 0;
			}
			@media only screen and (max-width: 992px) {
				.ant-space {
					flex-wrap: wrap;
				}
			}
		}
		.ant-select-selector {
			div * {
				display: flex;
				align-items: center;
			}
			.ant-select-selection-search {
				display: flex;
				align-items: center;
			}
		}
		.cancelation_policy_upperdiv {
			.ant-radio-group {
				display: flex;
				width: 100%;
				label {
					font-size: large;
					.ant-radio-checked + span {
						font-weight: 600;
					}
				}
				@media only screen and (max-width: 992px) {
					// flex-direction: column;
					flex-wrap: wrap;
					gap: 20px;
				}
			}
			& > .cancelation_policy_typesContainer {
				@include flex(center, flex-start);
				margin-top: 1rem;
				gap: 41px;
				@media only screen and (max-width: 992px) {
					gap: 21px;
					margin-bottom: 20px;
				}
			}
			& > span {
				@include font(22px, 500, $vibrarnt-black);
			}
		}
		& > .pricing_Form {
			max-width: 768px;
			margin: 30px 0px;
			background: #f0f0f0;
			border-radius: 8px;
			padding: 30px;

			& > .pricing_Form_currency {
				@include flex(center, flex-start);
				gap: 20px !important;
			}
		}
	}
}

.cancel-row-holder {
	display: flex;
	align-items: flex-start;
	gap: 10px;
	.remove-field-btn {
		margin-top: 47px;
	}
	> div {
		flex: 1;
		label {
			white-space: nowrap;
		}
	}
}
