.admin_profile_container {
	padding: 35px;
	.admin_profile_content_header {
		display: flex;
		flex-direction: column;
		gap: 20px;
		&.bg-white{

		background-color: #FFF;
		padding: 24px;
		border-radius: 12px;
		}
		.doc-profile-header{
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 10px;
			h2{
				margin-bottom: 0;
			}
		}
	}
	.form_container {
		.custom_form_input {
			&.change_password-container {
				justify-content: flex-start;
				position: relative;
				&:lang(ar) {
					justify-content: flex-end;
				}
				.update_message {
					position: absolute;
					bottom: -25px;
					inset-inline-start: 20px;
					color: #449d48;
					&.--error {
						color: #f33223;
					}
				}
			}
		}
	}
}
