@mixin Packages() {
	.packages-wrapper {
		padding-top: 20px;
		@media only screen and (max-width: 992px) {
			padding-top: 30px;
			.ant-breadcrumb {
				display: none;
			}
		}
		.packages-content-wrapper {
			@include flex(flex-start, flex-start);
			gap: 15px;
			margin-top: 31px;
			.result-container {
				width: calc(100% - 305px);
				min-height: 100vh;
				@media only screen and (max-width: 992px) {
					min-height: auto;
				}
				& > .result-container-sort {
					width: 100%;
					margin-bottom: 19px;
					@include flex(center, space-between);
					gap: 15px;
					.SelectSection {
						@include flex(center, center);
						flex-wrap: unset;
						gap: 16px;
						@media only screen and (max-width: 992px) {
							width: fit-content;
						}
						& > span {
							@include font(12px, 500, #8d8d8d);
							&.SelectSection__name {
								color: #8d8d8d !important;
							}
							&:hover {
								color: #fff;
							}
						}
					}
					& > p {
						margin: 0px;
						@include font(18px, 400, $black-color);
					}
				}
				& > .result-list-wrapper {
					margin: 24px 0px;
					@media only screen and (max-width: 992px) {
						display: grid;
						grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
						column-gap: 20px;
						.pagination-holder {
							grid-column: span 2;
						}
					}
					@media only screen and (max-width: 768px) {
						display: flex;
						flex-direction: column;
					}
				}
			}
			@media only screen and (max-width: 992px) {
				flex-direction: column;
				margin-top: 0;
				.result-container {
					width: 100%;
				}
			}
		}
	}
}

// .search-filter-data-holder {
//     width: 100%;
//     min-height: 411px;
//     background: #f4efff;
//     border-radius: 8px;
//     padding: 20px 17px;
//     .reset-open-filter-holder {
//         display: flex;
//         align-items: center;
//         justify-content: flex-end;
//         margin-bottom: 6px;

//         .close-icon-btn {
//             color: rgba(235, 42, 20, 0.8);
//             font-size: 25px;
//             display: none;
//         }
//         @media (max-width: 992px) {
//             margin-bottom: 26px;
//             justify-content: space-between;
//             .close-icon-btn {
//                 display: block;
//             }
//         }
//     }
//     .reset-form-holder {
//         font-size: 12px;
//         font-weight: 500;
//         color: #8d8d8d;
//         text-decoration: underline;
//         display: flex;
//         justify-content: flex-end;
//         cursor: pointer;
//         width: fit-content;
//         // margin-left: auto;
//         margin-bottom: 3px;
//     }

//     .select-dropdown-holder {
//         height: 40px !important;
//         display: flex;
//         align-items: center;
//         background-color: #fff;
//         padding: 3px 14px;
//         overflow: hidden;
//         .select-dropdown--label {
//             display: none;
//         }
//         .select-dropdown--icon {
//             margin-right: 10px;
//             svg {
//                 width: 25px;
//             }
//         }
//     }
// }

.result-container-sort {
	width: 100%;
	margin-bottom: 19px;
	@include flex(center, space-between);
	gap: 15px;
	.SelectSection {
		@include flex(center, center);
		flex-wrap: unset;
		gap: 16px;
		@media only screen and (max-width: 992px) {
			width: fit-content;
		}
		& > span {
			@include font(12px, 500, #8d8d8d);
			&.SelectSection__name {
				color: #8d8d8d !important;
			}
			&:hover {
				color: #fff;
			}
		}
	}
	& > p {
		margin: 0px;
		@include font(18px, 400, $black-color);
	}
}

.selection-sort-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.ant-select {
		width: 160px;
		box-shadow: none !important;
		.ant-select-selector {
			border-radius: 5px;
			border-color: #000;
			box-shadow: none !important;
		}
	}
	.hotels-result-info {
		.result-count {
			p {
				margin: 0px;
				@include font(18px, 400, $black-color);
			}
		}
	}
	.SelectSection__name {
		white-space: nowrap;
	}
	@media only screen and (max-width: 992px) {
		width: 100%;
	}
	.selection-row-holder {
		display: none;
		@media only screen and (max-width: 768px) {
			display: block;
		}
	}
}
