@mixin PopularPackage {
    .PopularPackageContainer {
        width: 100%;
        padding: 0px 20px;
        // padding-top: 90px;
        .PopularPackageSubContainer {
            max-width: 1432px;
            min-height: 400px;
            margin: auto;
            background: $white_color;
            .PopularPackage_Title {
                margin-top: 20px !important;
                display: inline-block;
                @include font(46px, 600, $vibrarnt-black);
            }
            .PopularPackage_SubTitle {
                @include flex(center, space-between);
                margin-top: 12px;
                & > p {
                    @include font(22px, 500, $grey);
                    margin: 0px;
                }
                & > span {
                    text-decoration: underline;
                    @include font(22px, 500, $lightt-grey);
                    margin: 0px;
                    cursor: pointer;
                }
            }
            .PopularPackage_Boxes {
                column-gap: 20px;
                row-gap: 20px;
                margin-top: 20px;
                @include flex(center, flex-start);
                .PopularPackage_Box {
                    width: 343px;
                    height: 100%;
                    .PopularPackage_Image {
                        position: relative;
                        width: 343px;
                        height: 518px;
                        margin-bottom: 20px;
                        .PopularPackage_Image {
                            width: 343px !important;
                            height: 518px !important;
                        }
                        .PopularPackage_Badge {
                            position: absolute;
                            z-index: 1;
                            top: 18px;
                            left: 18px;
                            right: 18px;
                            @include flex(center, center);
                            column-gap: 6px;
                            width: 160px;
                            height: 42px;
                            box-shadow: 0px 13px 16px #4cb78a29;
                            border-radius: 21px;
                            span {
                                width: 24px !important;
                                height: 24px !important;
                                position: relative !important;
                                img {
                                    position: relative !important;
                                }
                            }
                            p {
                                margin: 0px;
                                @include font(15px, bold, $white_color);
                            }
                        }
                    }
                    .PopularPackage_Details {
                        @include flex(center, space-between);
                        flex-wrap: unset;
                        margin-bottom: 12px;
                        height: 10px;
                        & > span {
                            @include font(20px, 600, $vibrarnt-black);
                        }
                        .PopularPackage_Details_No {
                            @include flex(center, center);
                            flex-wrap: unset;
                            column-gap: 7px;
                            p {
                                @include font(15px, 500, $lightt-grey);
                            }
                            .PopularPackage_Details_Image {
                                position: relative;
                                width: 20px;
                                height: 20px;
                                img {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                    .PopularPackage_Price {
                        @include font(20px, 500, $primary_color);
                        @include flex(center, flex-start);
                        column-gap: 5px;
                        & > span {
                            @include font(24px, bold, $primary_color);
                        }
                    }
                }
                .PopularPackage_Box_Compact {
                    display: flex;
                    flex-direction: column;
                    row-gap: 21px;
                    width: 343px;
                    height: 100%;
                    .PopularPackage_CompactDiv {
                        width: 100%;
                        height: 100%;
                        .PopularPackage_Image {
                            position: relative;
                            width: 343px;
                            height: 205px;
                            margin-bottom: 21px;
                            .PopularPackage_Image {
                                width: 343px !important;
                                height: 205px !important;
                            }
                        }
                        .PopularPackage_Details {
                            @include flex(center, space-between);
                            flex-wrap: unset;
                            margin-bottom: 13px;
                            height: 10px;
                            & > span {
                                @include font(20px, 600, $vibrarnt-black);
                            }
                            .PopularPackage_Details_No {
                                @include flex(center, flex-start);
                                flex-wrap: unset;
                                column-gap: 7px;
                                p {
                                    @include font(15px, 500, $lightt-grey);
                                }
                                .PopularPackage_Details_Image {
                                    position: relative;
                                    width: 20px;
                                    height: 20px;
                                    img {
                                        width: 20px;
                                        height: 20px;
                                    }
                                }
                            }
                        }
                        .PopularPackage_Price {
                            @include font(20px, 500, $primary_color);
                            @include flex(center, flex-start);
                            column-gap: 5px;
                            & > span {
                                @include font(24px, bold, $primary_color);
                            }
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 1440px) and (max-width: 1920px), (max-width: 1440px), (max-height: 768px) {
        .PopularPackageContainer {
            .PopularPackageSubContainer {
                max-width: 1200px;
                min-height: 400px;
                .PopularPackage_Title {
                    @include font(36px, 600, $vibrarnt-black);
                }
                .PopularPackage_SubTitle {
                    & > p {
                        @include font(16px, 500, $grey);
                    }
                    & > span {
                        @include font(16px, 500, $lightt-grey);
                    }
                }
                .PopularPackage_Boxes {
                    column-gap: 16px;
                    row-gap: 16px;
                    margin-top: 34px;
                    .PopularPackage_Box {
                        width: 278px;
                        .PopularPackage_Image {
                            width: 278px;
                            height: 419px;
                            margin-bottom: 16px;
                            .PopularPackage_Image {
                                width: 278px !important;
                                height: 419px !important;
                            }
                            .PopularPackage_Badge {
                                width: 130px;
                                height: 34px;
                                span {
                                    width: 19px !important;
                                    height: 19px !important;
                                }
                                p {
                                    @include font(12px, bold, $white_color);
                                }
                            }
                        }
                        .PopularPackage_Details {
                            margin-bottom: 11px;
                            & > span {
                                @include font(17px, 600, $vibrarnt-black);
                            }
                            .PopularPackage_Details_No {
                                p {
                                    @include font(12px, 500, $lightt-grey);
                                }
                                .PopularPackage_Details_Image {
                                    width: 16px;
                                    height: 16px;
                                    img {
                                        width: 16px;
                                        height: 16px;
                                    }
                                }
                            }
                        }
                        .PopularPackage_Price {
                            @include font(15px, 500, $primary_color);
                            & > span {
                                @include font(17px, bold, $primary_color);
                            }
                        }
                    }
                    .PopularPackage_Box_Compact {
                        row-gap: 16px;
                        width: 278px;
                        .PopularPackage_CompactDiv {
                            .PopularPackage_Image {
                                width: 278px;
                                height: 166px;
                                margin-bottom: 16px;
                                .PopularPackage_Image {
                                    width: 278px !important;
                                    height: 166px !important;
                                }
                            }
                            .PopularPackage_Details {
                                margin-bottom: 10px;
                                & > span {
                                    @include font(17px, 600, $vibrarnt-black);
                                }
                                .PopularPackage_Details_No {
                                    p {
                                        @include font(12px, 500, $lightt-grey);
                                    }
                                    .PopularPackage_Details_Image {
                                        width: 16px;
                                        height: 16px;
                                        img {
                                            width: 16px;
                                            height: 16px;
                                        }
                                    }
                                }
                            }
                            .PopularPackage_Price {
                                @include font(15px, 500, $primary_color);
                                & > span {
                                    @include font(17px, bold, $primary_color);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 1280px), (max-height: 800px) {
        .PopularPackageContainer {
            .PopularPackageSubContainer {
                max-width: 1004px;
                min-height: 275px;
                .PopularPackage_Title {
                    @include font(28px, 600, $vibrarnt-black);
                }
                .PopularPackage_SubTitle {
                    & > p {
                        @include font(14px, 500, $grey);
                    }
                    & > span {
                        @include font(14px, 500, $lightt-grey);
                    }
                }
                .PopularPackage_Boxes {
                    column-gap: 13px;
                    row-gap: 15px;
                    margin-top: 26px;
                    .PopularPackage_Box {
                        width: 240px;
                        .PopularPackage_Image {
                            position: relative;
                            width: 240px;
                            height: 363px;
                            .PopularPackage_Image {
                                width: 240px !important;
                                height: 363px !important;
                            }
                            .PopularPackage_Badge {
                                width: 112px;
                                height: 30px;
                                border-radius: 21px;
                                span {
                                    width: 17px !important;
                                    height: 17px !important;
                                }
                                p {
                                    @include font(10px, bold, $white_color);
                                }
                            }
                        }
                        .PopularPackage_Details {
                            margin-bottom: 8px;
                            & > span {
                                @include font(14px, 600, $vibrarnt-black);
                            }
                            .PopularPackage_Details_No {
                                p {
                                    @include font(10px, 500, $lightt-grey);
                                }
                                .PopularPackage_Details_Image {
                                    width: 15px;
                                    height: 15px;
                                    img {
                                        width: 15px;
                                        height: 15px;
                                    }
                                }
                            }
                        }
                        .PopularPackage_Price {
                            @include font(14px, 500, $primary_color);
                            & > span {
                                @include font(18px, bold, $primary_color);
                            }
                        }
                    }
                    .PopularPackage_Box_Compact {
                        row-gap: 15px;
                        width: 240px;
                        .PopularPackage_CompactDiv {
                            .PopularPackage_Image {
                                position: relative;
                                width: 240px;
                                height: 143px;
                                margin-bottom: 20px;
                                .PopularPackage_Image {
                                    width: 240px !important;
                                    height: 143px !important;
                                }
                            }
                            .PopularPackage_Details {
                                margin-bottom: 8px;
                                height: 10px;
                                & > span {
                                    @include font(14px, 600, $vibrarnt-black);
                                }
                                .PopularPackage_Details_No {
                                    @include flex(center, flex-start);
                                    flex-wrap: unset;
                                    column-gap: 7px;
                                    p {
                                        @include font(10px, 500, $lightt-grey);
                                    }
                                    .PopularPackage_Details_Image {
                                        position: relative;
                                        width: 15px;
                                        height: 15px;
                                        img {
                                            width: 15px;
                                            height: 15px;
                                        }
                                    }
                                }
                            }
                            .PopularPackage_Price {
                                @include font(14px, 500, $primary_color);
                                @include flex(center, flex-start);
                                column-gap: 5px;
                                & > span {
                                    @include font(18px, bold, $primary_color);
                                }
                            }
                        }
                    }
                }
                .PopularPackage_Slider {
                    margin-top: 22px;
                    .PopularPackage_Swiper {
                        height: 200px;
                        width: 100%;
                        .swiper-wrapper {
                            margin: auto;
                        }
                        .swiper {
                            width: 100%;
                            height: 100%;
                        }
                        .swiper-slide {
                            height: 200px;
                            width: 150px !important;
                            border-radius: 8px;
                            .PopularPackage_Swipper_Image {
                                position: relative;
                                width: 150px;
                                height: 123px;
                                margin-bottom: 10px;
                                border-radius: 8px;
                                .PopularPackage_Swipper_ImageI {
                                    width: 150px !important;
                                    height: 123px !important;
                                    border-radius: 8px;
                                }
                                .PopularPackage_Badge {
                                    position: absolute;
                                    z-index: 1;
                                    top: 12px;
                                    right: 9px;
                                    @include flex(center, center);
                                    column-gap: 6px;
                                    width: 89px;
                                    height: 29px;
                                    box-shadow: 0px 13px 16px #4cb78a29;
                                    border-radius: 21px;
                                    span {
                                        width: 14px !important;
                                        height: 14px !important;
                                        position: relative !important;
                                        img {
                                            position: relative !important;
                                        }
                                    }
                                    p {
                                        margin: 0px;
                                        @include font(9px, bold, $white_color);
                                    }
                                }
                            }
                            .PopularPackage_Details {
                                @include flex(center, space-between);
                                flex-wrap: unset;
                                margin-bottom: 8px;
                                height: 10px;
                                & > span {
                                    @include font(14px, 600, $vibrarnt-black);
                                }
                                .PopularPackage_Details_No {
                                    @include flex(center, center);
                                    flex-wrap: unset;
                                    column-gap: 7px;
                                    p {
                                        @include font(10px, 500, $lightt-grey);
                                    }
                                    .PopularPackage_Details_Image {
                                        position: relative;
                                        width: 15px;
                                        height: 15px;
                                        img {
                                            width: 15px;
                                            height: 15px;
                                        }
                                    }
                                }
                            }
                            .PopularPackage_Price {
                                @include font(9px, 500, $primary_color);
                                @include flex(center, flex-start);
                                column-gap: 5px;
                                & > span {
                                    @include font(12px, bold, $primary_color);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 768px) {
        .PopularPackageContainer {
            padding-top: 0;
            .PopularPackageSubContainer {
                max-width: 1005px;
                min-height: 100px;
                .PopularPackage_Title {
                    margin-top: 20px !important;
                    @include font(18px, 600, $vibrarnt-black);
                }
            }
        }
        .Carousal_Swiper_FirstSection {
            margin-bottom: 10px;
            gap: 10px;
            width: calc(100vw - 30px) !important;
            height: auto !important;
            overflow: auto;
            .Carousal_Swiper_TabFragment {
                backdrop-filter: initial !important;
                width: auto !important;
                height: 48px !important;
                .Carousal_Swiper_TabSection {
                    border-radius: 30px !important;
                    white-space: nowrap !important;
                    flex-wrap: nowrap !important;
                    width: auto !important;
                    padding: 0 10px !important;
                    p {
                        font-size: 12px !important;
                    }
                }
            }
        }
        .Carousal_Swiper_SecondSection {
            height: auto !important;
            > div {
                width: 100% !important;
            }
        }
    }
}
