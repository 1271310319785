@mixin Inspiration {
    @media (min-width: 768px) {
        .InspirationContainer {
            background: #f8f8f8;
            padding: 0px 20px;
            .InspirationSubContainer {
                max-width: 1415px;
                min-height: 450px;
                background: #f8f8f8;
                margin: auto;
                padding: 50px 0px;
                .InspirationContainer_Tabs_Container {
                    width: 100%;
                    @include flex(center, flex-start);
                    row-gap: 20px;
                    column-gap: 68px;
                    border-bottom: 1px solid #dddddd;
                    span {
                        height: 35px;
                        @include font(18px, 500, $grey);
                        padding-bottom: 42px;
                        cursor: pointer;
                    }
                    .InspirationContainer_TabActive {
                        height: 35px;
                        @include font(18px, 600, $vibrarnt-black);
                        border-bottom: 2px solid $black-color;
                    }
                }
                .Inspiration_Title {
                    margin-bottom: 35px;
                    display: inline-block;
                    height: 36px;
                    @include font(26px, 600, $black-color);
                }
                .Inspiration_Boxes_Container {
                    @include flex(center, flex-start);
                    width: 100%;
                    row-gap: 50px;
                    column-gap: 72px;
                    margin-top: 85px;
                    .Inspiration_Box_Container {
                        @include flex(center, space-between);
                        column-gap: 12px;
                        height: 92px;
                        .Inspiration_Box_Container_Image {
                            position: relative;
                            width: 92px;
                            height: 92px;
                            .Inspiration_Box_Container_ImageI {
                                width: 92px !important;
                                height: 92px !important;
                            }
                        }
                    }
                    .Inspiration_Box_Container_Details {
                        flex-direction: column;
                        @include flex(flex-start, space-between);
                        height: 100%;
                        span {
                            @include font(16px, 600, $vibrarnt-black);
                        }
                        p {
                            @include font(16px, 500, $grey);
                            margin: 0px;
                        }
                    }
                }
            }
        }
        @media (min-width: 1440px) and (max-width: 1920px), (max-width: 1440px), (max-height: 768px) {
            .InspirationContainer {
                .InspirationSubContainer {
                    max-width: 1155px;
                    padding: 105px 0px;
                    .InspirationContainer_Tabs_Container {
                        column-gap: 31px;
                        span {
                            @include font(16px, 500, $grey);
                            padding-bottom: 42px;
                        }
                        .InspirationContainer_TabActive {
                            @include font(16px, 600, $vibrarnt-black);
                        }
                    }
                    .Inspiration_Title {
                        @include font(21px, 600, $black-color);
                    }
                    .Inspiration_Boxes_Container {
                        row-gap: 50px;
                        column-gap: 72px;
                        margin-top: 74px;
                        .Inspiration_Box_Container {
                            column-gap: 9px;
                            height: 74px;
                            .Inspiration_Box_Container_Image {
                                width: 74px;
                                height: 74px;
                                .Inspiration_Box_Container_ImageI {
                                    width: 74px !important;
                                    height: 74px !important;
                                }
                            }
                        }
                        .Inspiration_Box_Container_Details {
                            span {
                                @include font(12px, 600, $vibrarnt-black);
                            }
                            p {
                                @include font(12px, 500, $grey);
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 1280px), (max-height: 800px) {
            .InspirationContainer {
                .InspirationSubContainer {
                    max-width: 995px;
                    padding: 50px 0px;
                    .InspirationContainer_Tabs_Container {
                        row-gap: 20px;
                        column-gap: 55px;
                        span {
                            height: 35px;
                            @include font(12px, 500, $grey);
                            padding-bottom: 17px;
                        }
                        .InspirationContainer_TabActive {
                            height: 35px;
                            @include font(12px, 600, $vibrarnt-black);
                        }
                    }
                    .Inspiration_Title {
                        margin-top: 14px;
                        margin-bottom: 35px;
                        height: 36px;
                        @include font(17px, 600, $black-color);
                    }
                    .Inspiration_Boxes_Container {
                        row-gap: 50px;
                        column-gap: 60px;
                        margin-top: 36px;
                        .Inspiration_Box_Container {
                            column-gap: 8px;
                            height: 64px;
                            .Inspiration_Box_Container_Image {
                                width: 64px;
                                height: 64px;
                                .Inspiration_Box_Container_ImageI {
                                    width: 64px !important;
                                    height: 64px !important;
                                }
                            }
                        }
                        .Inspiration_Box_Container_Details {
                            span {
                                @include font(12px, 600, $vibrarnt-black);
                            }
                            p {
                                @include font(10px, 500, $grey);
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 950px) {
            .InspirationContainer {
                display: none;
            }
        }
    }
}
