@mixin StartTravelShop() {
    @media (min-width: 768px) {
        .StartTravelShopContainer {
            max-width: 1435px;
            min-height: 400px;
            margin: 50px auto;
            padding: 0px 20px;
            background: $white_color;
            @include flex(center, center);
            .StartTravelShop_Box {
                position: relative;
                min-height: 328px;
                width: 100%;
                background: transparent linear-gradient(104deg, #ffd577 0%, #fadeff 100%);
                border-radius: 25px;
                @include flex(center, space-between);
                .StartTravelShop_Box_Left {
                    width: 1036px;
                    padding: 0px 80px;
                    .StartTravelShop_Box_Title {
                        @include font(46px, bold, $black-color);
                    }
                    & > p {
                        min-height: 81px;
                        @include font(14px, 500, $black-color);
                        max-width: 700px;
                    }
                    .StartTravelShop_Box_Services {
                        @include flex(center, flex-start);
                        width: 100%;
                        column-gap: 20px;
                        row-gap: 20px;
                        .StartTravelShop_Box_Services_Box {
                            width: 204px;
                            height: 82px;
                            background: $white_color;
                            box-shadow: 0px 7px 27px #ff791729;
                            border-radius: 12px;
                            column-gap: 14px;
                            @include flex(center, center);
                            .StartTravelShop_Box_Services_Box_Avatar {
                                position: relative;
                                width: 41px;
                                height: 41px;
                                .box-avatar-image {
                                    width: 41px !important;
                                    height: 41px !important;
                                }
                            }
                            & > p {
                                @include font(12px, 500, $black-color);
                                cursor: pointer;
                            }
                        }
                    }
                }
                .StartTravelShop_Box_Right {
                    width: 337px;
                    height: 332px;
                    position: relative;
                    .StartTravelShop_Box_Avatar {
                        position: absolute;
                        top: 20px;
                        & > svg {
                            width: 337px;
                            height: 332px;
                        }
                    }
                }
            }
        }
        @media (min-width: 1440px) and (max-width: 1920px), (max-width: 1440px), (max-height: 768px) {
            .StartTravelShopContainer {
                max-width: 1205px;
                min-height: 400px;
                .StartTravelShop_Box {
                    min-height: 265px;
                    border-radius: 18px;
                    .StartTravelShop_Box_Left {
                        width: 868px;
                        .StartTravelShop_Box_Title {
                            @include font(36px, bold, $black-color);
                        }
                        & > p {
                            @include font(12px, 500, $black-color);
                            max-width: 992px;
                        }
                        .StartTravelShop_Box_Services {
                            column-gap: 16px;
                            row-gap: 16px;
                            .StartTravelShop_Box_Services_Box {
                                width: 165px;
                                height: 66px;
                                column-gap: 9px;
                                .StartTravelShop_Box_Services_Box_Avatar {
                                    width: 33px;
                                    height: 33px;
                                    .box-avatar-image {
                                        width: 33px !important;
                                        height: 33px !important;
                                    }
                                }
                                & > p {
                                    @include font(10px, 500, $black-color);
                                }
                            }
                        }
                    }
                    .StartTravelShop_Box_Right {
                        width: 250px;
                        height: 269px;
                        .StartTravelShop_Box_Avatar {
                            & > svg {
                                width: 272px;
                                height: 269px;
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 1280px), (max-height: 800px) {
            .StartTravelShopContainer {
                max-width: 1040px;
                min-height: 250px;
                margin: 50px auto;
                .StartTravelShop_Box {
                    position: relative;
                    min-height: 250px;
                    border-radius: 25px;
                    row-gap: 20px;
                    column-gap: 80px;
                    @include flex(center, center);
                    .StartTravelShop_Box_Left {
                        padding: 23px 0px;
                        width: 614px;
                        .StartTravelShop_Box_Title {
                            @include font(32px, bold, $black-color);
                        }
                        & > p {
                            min-height: 81px;
                            @include font(14px, 500, $black-color);
                            max-width: 700px;
                        }
                        .StartTravelShop_Box_Services {
                            width: 100%;
                            column-gap: 14px;
                            row-gap: 14px;
                            .StartTravelShop_Box_Services_Box {
                                width: 143px;
                                height: 59px;
                                border-radius: 12px;
                                column-gap: 9px;
                                .StartTravelShop_Box_Services_Box_Avatar {
                                    width: 29px;
                                    height: 29px;
                                    .box-avatar-image {
                                        width: 29px !important;
                                        height: 29px !important;
                                    }
                                }
                                & > p {
                                    @include font(10px, 500, $black-color);
                                }
                            }
                        }
                    }
                    .StartTravelShop_Box_Right {
                        width: 195px;
                        height: 250px;
                        .StartTravelShop_Box_Avatar {
                            position: absolute;
                            top: 20px;
                            & > svg {
                                width: 195px;
                                height: 250px;
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 950px) {
            .StartTravelShopContainer {
                display: none;
            }
        }
    }
}
