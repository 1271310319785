body {
	overflow-x: hidden;
}

// Colors
.text-secondary {
	color: #8d8d8d;
	&:hover {
		color: #797979;
	}
}
.text-underline {
	text-decoration: underline;
	text-transform: capitalize;
}
.label-ellipsis {
	label {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}
.input-border-radio {
	.ant-radio-wrapper span.ant-radio + * {
		@media only screen and (max-width: 768px) {
			font-size: 12px;
		}
	}
}
.text-ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.permissions-grid-holder {
	.ant-checkbox-group {
		display: grid;
		grid-template-columns: 1fr 1fr;
		@media only screen and (max-width: 768px) {
			grid-template-columns: 1fr;
		}
	}
}
.change_password-modal {
	.ant-modal-header {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		font-size: 18px;
	}
	.ant-modal-content {
		border-radius: 1rem;
		overflow: hidden;
	}
}
.placeholder-image {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 70px;
	background-color: #e3e3e3;
	padding: 12px;
	border-radius: 8px;
	svg {
		width: 100%;
		height: 100%;
	}
}
.ant-dropdown-menu-item {
	&.ant-dropdown-menu-item-danger {
		&.ant-dropdown-menu-item-disabled {
			color: rgba(0, 0, 0, 0.25) !important;
		}
	}
}
.divider-h {
	width: 100%;
	height: 2px;
	background-color: #a7a7a7;
}

.pagination-holder {
	border: 0px;
	background-color: transparent;
	@include flex(center, center);
	margin-top: 20px;
	margin-bottom: 20px;
}

.package_filter_container {
	width: 100%;
	min-height: 99px;
	background: #f8f8f8;
	border-radius: 8px;
	margin: 16.5px auto;
	// @include flex(center, space-between);
	display: grid;
	grid-template-columns: 3fr 2.5fr 1.5fr 2.5fr;
	padding: 10px;
	gap: 12px;
	.ant-picker-suffix {
		position: absolute;
		right: 15px;
		&:lang(ar) {
			right: auto;
			left: 15px;
		}
	}
	@media only screen and (max-width: 992px) {
		background: transparent;
		padding: 0;
		grid-template-columns: repeat(1, 100%);
		.ant-picker-range {
			width: 100%;
		}
	}
	&.three-col {
		grid-template-columns: 3fr 3fr 1fr;
		.ant-picker-range,
		.ant-select {
			width: 100%;
		}
		.ant-select-selector {
			height: 43px;
			.ant-select-selection-item {
				line-height: 43px;
			}
		}
		@media only screen and (max-width: 768px) {
			display: flex;
			flex-wrap: wrap;
			& > .filter_option {
				min-width: 48%;
				flex: 1;
				&:first-of-type {
					min-width: 100%;
				}
			}
		}
	}
	& > .filter_option {
		@include flex(flex-start, center);
		flex-direction: column;
		gap: 5px;
		& > span {
			@include font(12px, 400, $black-color);
		}
		.ant-select.package-select {
			width: 100%;
			height: 45px;
			.ant-select-selector {
				height: 45px;
				.ant-select-selection-item {
					padding-top: 6px;
				}
			}
		}
		.package-search,
		.ant-input-wrapper,
		.ant-input-affix-wrapper,
		.ant-input-search-button {
			height: 43px;
		}
		.ant-input-affix-wrapper {
			box-shadow: none;
			&:not(.package-search) {
				// All classes below are for removing border from input except .package-search class
				border-inline-end: none;
			}
			svg {
				color: #d9d9d9;
			}
			&:focus,
			&:hover {
				border-color: #d9d9d9;
			}
		}
		.ant-input-search-button {
			border-inline-start: 0 !important;
			&:focus,
			&:hover {
				border-color: #d9d9d9;
			}
		}
		.ant-picker-range {
			height: 43px;
			background: #fff;
			border: 1px solid #d9d9d9;
			border-radius: 8px;
			& > .ant-picker-input {
				width: 80px;
				&:nth-of-type(3) {
					width: 100px;
				}
			}
		}
	}
	& > .package_filter_container_date {
		@media only screen and (max-width: 992px) {
			order: -1;
		}
	}
}

.package-booking_Content {
	.package_Content_Title_P {
		column-gap: 16px;
		font-size: 28px;
		font-weight: 600;
		color: #171717;
		margin: 0px;
		margin-bottom: 40px;
		.rotate-arrow {
			display: inline-flex;
			transition: all 0.3s ease;
			// Arabic Version
			&:lang(ar) {
				transform: rotate(180deg);
			}
		}
	}
}

.is-disabled {
	opacity: 0.5;
	pointer-events: none;
}

.skeleton-card-holder {
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	padding: 10px;
	border: 1px solid #c9c9c9;
	border-radius: 12px;
	gap: 20px;
	.ant-skeleton-element .ant-skeleton-image {
		width: 213px;
		height: 188px;
		@media only screen and (max-width: 992px) {
			width: 96px;
			height: 96px;
		}
	}
	@media only screen and (max-width: 992px) {
		.ant-skeleton-paragraph {
			> li:first-of-type {
				display: none;
			}
		}
	}
}

.search-form-holder {
	.ant-form-item {
		margin-bottom: 15px;
		&.input-popover {
			margin-bottom: 0;
			.ant-input-number-input {
				width: 80px;
			}
		}
		.ant-form-item-label {
			padding-bottom: 4px;
			user-select: none;
			label {
				font-size: 14px;
				font-weight: 500;
				color: #4f4f4f;
				height: auto;
				user-select: none;
			}
		}
		.ant-select-selection-item {
			font-weight: 500;
			font-size: 14px;
			color: #000;
			b {
				font-weight: 500;
			}
		}
		.ant-picker {
			.ant-picker-suffix {
				order: -1;
				margin-inline-end: 10px;
				margin-inline-start: 0;
				font-size: 20px;
				color: #000;
			}
		}
		.ant-input-affix-wrapper {
			.ant-input-prefix {
				margin-inline-end: 10px;
				margin-inline-start: 0;
				font-size: 20px;
				color: #000;
			}
		}
		.ant-select {
			.ant-select-selector {
				padding-inline-start: 40px;
				input {
					padding-inline-start: 30px;
				}
			}
			.ant-select-arrow {
				order: -1;
				font-size: 20px;
				color: #000;
				left: 11px;
				right: auto;
				&:lang(ar) {
					right: 11px;
					left: auto;
				}
			}
			.ant-select-selection-placeholder {
				font-size: 14px;
			}
		}
		.ant-input-affix-wrapper,
		.ant-select-selector,
		.ant-picker {
			height: 40px;
			width: 100%;
			background: #fff;
			border: 1px solid #dadde3;
			border-radius: 8px;
			input {
				font-weight: 500;
				font-size: 14px;
				color: #000;
				&::-webkit-input-placeholder {
					// color: #000;
					color: rgba(0, 0, 0, 0.25);
					font-size: 14px;
					font-weight: 500;
				}

				&:-ms-input-placeholder {
					// color: #000;
					color: rgba(0, 0, 0, 0.25);
					font-size: 14px;
					font-weight: 500;
				}

				&::placeholder {
					// color: #000;
					color: rgba(0, 0, 0, 0.25);
					font-size: 14px;
					font-weight: 500;
				}
			}
		}
	}
	.input-popover .ant-form-item-control-input-content {
		width: 100%;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #fff;
		border: 1px solid #dadde3;
		border-radius: 8px;
		padding: 6.5px 11px 6.5px;
		.anticon-team {
			margin-inline-end: 10px;
			margin-inline-start: 0;
			font-size: 20px;
			color: #000;
		}
		.popover-content {
			flex: 1;
			font-weight: 500;
			font-size: 14px;
			color: #000;
			padding: 0;
		}
		.ant-input-number-group-addon .anticon {
			font-size: 22px !important;
		}
	}
	.anticon.ant-input-clear-icon,
	.ant-input-clear-icon,
	.ant-picker-clear,
	.ant-select-clear {
		font-size: 16px;
	}
	[type="submit"] {
		margin-top: 20px;
	}
}
.max-capacity-option {
	.ant-select-item-option-content {
		display: grid;
		grid-template-columns: 1fr 1fr;
		b {
			font-weight: 500;
		}
	}
}
.custom-column-table {
	.ant-col:first-child {
		// background: #f2f2f0;
	}
	.ant-form-item-control-input {
		min-height: 45px;
	}
}
//* package sortBy select
.ant-select-dropdown {
	border-radius: 12px;
}

.review-publish-holder {
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 155px);
}
.without-padding {
	.ant-modal-content {
		padding: 0;
	}
}
.PhoneInputCountrySelectArrow {
	margin-inline-start: 0.5rem;
}
.admin-modal {
	// margin: 3rem 0rem;
	.ant-modal-content {
		border-radius: 20px !important;
		overflow: hidden;
		.ant-modal-close {
			margin-top: 1rem;
		}
		.ant-modal-header {
			display: flex;
			justify-content: center;
			padding: 2rem;
		}
		.ant-modal-body {
			.inline-switch {
				display: flex;
				flex-direction: row;
				width: 100%;
				align-items: center;
				.ant-form-item-row {
					display: flex;
					flex-direction: row;
					width: 100%;
					margin-top: 1rem;
					.ant-form-item-label {
						label {
							font-size: 16px;
							font-weight: 500 !important;
							color: #171717;
							margin: 0;
						}
						padding-bottom: 0;
					}
					.ant-col {
						width: auto;
					}
					.inline-switch-input {
						width: auto;
					}
				}
			}
			.submit-btn {
				margin-top: 2rem;
			}
			.input-holder {
				margin-bottom: 1rem;
			}
		}
	}
}
.nusuk-iframe-wrapper {
	width: 100%;
	min-height: 100vh;
	#top-bar {
		display: none;
	}
	header {
		display: none;
	}
}
.base-line-height {
	* {
		line-height: 1.6;
	}
}
.RecommendStatus_Box {
	.ant-select {
		width: 150px;
		font-size: 0.8rem;

		.ant-select-selector {
			border: 2px solid #aaaaaa;
			background-color: unset;
			background-image: unset;
			padding: 5px 11px;
			height: 42px;
		}
	}
}

.RecommendStatus_Swipper_Rating {
	.ant-rate {
		height: 34px;
		margin-inline-start: 10px;
	}

	.ant-rate-star {
		font-size: 0.85rem;
	}
}
.light-label {
	label {
		font-weight: normal !important;
		font-size: 16px !important;
	}
}

.container-size-small {
	height: 40px !important;
}
.discount_container {
	width: auto;
	height: 40px;
	background: $white_color;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #dadde3;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
	user-select: none;
	* {
		user-select: none;
	}
	input {
		text-align: center;
		width: 5rem;
		@include font(14px, bold, #171717);
	}
	& > .value {
		@include font(13px, bold, #171717);
		text-align: center;
		border: 0;
		height: 100%;
		padding: 0;
		display: flex;
		justify-content: center;
		padding-top: 5px;
	}
	& > .action-btn {
		@include flex(center, center);
		padding: 0.5rem 1rem;
		// position: absolute;
		span {
			cursor: pointer;
		}
		&:lang(ar) {
			left: 0;
		}
		&:lang(en) {
			right: 0;
		}
	}
}
.label-hidden {
	label {
		visibility: hidden;
	}
}
.font-weight-500 {
	font-weight: 500;
}
.input-holder-group {
	margin-bottom: 0;
	.ant-radio-button-wrapper {
		height: 50px;
		display: inline-flex;
		align-items: center;
	}
}
.input-filter-holder {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.filter-btn {
		width: 2.5rem !important;
		height: 2.5rem !important;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 2px;
	}
	.ant-col.ant-form-item-control {
		background-color: transparent !important;
	}
	.input-holder {
		width: 100%;
	}
}
.twitter-picker {
	z-index: 999;
}
.copy-prefix-holder {
	// display: flex;
	// align-items: center;
	.ant-input-affix-wrapper {
		width: 100%;
		position: relative;
		padding: 0;
	}
	.ant-input-prefix {
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 99;
		padding: 0;
		margin: 0;
		right: 0;
		&:lang(ar) {
			left: 0;
			right: unset;
		}
		button {
			height: 100% !important;
		}
	}
}
.card-dropdown-overlay {
	.ant-dropdown-menu-item {
		padding: 1rem 2rem !important;
		svg {
			height: 1rem;
			width: 1rem;
		}
	}
}
.data-row-holder {
	display: grid;
	gap: 0.5rem;
	grid-template-columns: 1fr 1fr;
	@media only screen and (max-width: 992px) {
		grid-template-columns: 1fr;
	}
}
.ant-table-selection-column {
	background-color: transparent !important;
	padding: 0 !important;
	label {
		min-width: 100%;
		margin: auto;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.package_Content_Result_Boxes {
	@media (max-width: 992px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 20px;
		.empty-state-holder,
		.ant-pagination {
			grid-column: span 2;
		}
		@media (max-width: 580px) {
			grid-template-columns: 1fr;
			.empty-state-holder,
			.ant-pagination {
				grid-column: span 1;
			}
		}
	}
}
.share-modal-holder {
	@media (max-width: 580px) {
		.ant-modal-content {
			padding: 24px 0px;
			.ant-modal-header {
				padding: 0 20px;
			}
		}
	}
}
.danger-txt {
	color: #ff4d4f !important;
	svg {
		path {
			fill: #ff4d4f !important;
			stroke: #ff4d4f !important;
		}
	}
	& + .ant-menu-title-content {
		color: #ff4d4f !important;
	}
}

.rc-virtual-list-scrollbar {
	&:lang(ar) {
		left: 0 !important;
		right: auto !important;
	}
}
.no-bottom-margin {
	margin-bottom: 0 !important;
}
.rtl-icon {
	&:lang(ar) {
		transform: rotate(180deg);
	}
}
.payment-stripe-holder {
	padding: 25px 20px;
	border-radius: 12px;
	box-shadow: 0px 13px 26px rgba(0, 0, 0, 0.0784313725);
	background-color: #fff;
	.StripeElement {
		margin-bottom: 20px;
	}
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
	&:lang(ar) {
		flex-direction: column-reverse;
	}
}

.market-home-wrapper {
	padding-top: 30px;
}
.grid-two-col {
	grid-column: span 2;
}
.app-spin-holder {
	.ant-spin-container.ant-spin-blur {
		height: 100vh;
		overflow: hidden;
		opacity: 0;
	}
}
.count-down-modal {
	.ant-modal {
		width: 65vw !important;
		max-width: 800px;
		// .ant-modal-content {
		// 	width: 65vw !important;
		// 	max-width: 800px;
		// }
		@media (max-width: 1200px) {
			width: 75vw !important;
			// .ant-modal-content {
			// 	width: 75vw !important;
			// }
		}
		@media (max-width: 768px) {
			width: 95% !important;
			// .ant-modal-content {
			// 	width: 95% !important;
			// }
		}
	}
	.ant-modal-content {
		background: linear-gradient(0deg, #25064c, #25064c), linear-gradient(270deg, #8a4cf5 0%, #1727ff 100%);
		padding-bottom: 32px;
		// min-height: 65vh;
		// height: 530px;
		@media (max-width: 768px) {
			height: auto;
		}
		.ant-modal-header {
			width: fit-content;
			background-color: transparent;
			margin-bottom: 7vh;

			.ant-modal-close-x {
				background: rgba(89, 89, 89, 0.3);
				border-radius: 4px;
				svg {
					color: #fff;
				}
			}
		}
		.logo-beta-holder {
			display: flex;
			align-items: center;
			gap: 16px;
			.logo-beta--img {
				position: relative;
				width: 100px;
				height: 30px;
				img {
					height: auto;
					object-fit: contain;
				}
			}
			.beta-holder {
				width: 44px;
				height: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 4px 8px;
				background: #ff9d00;
				border-radius: 4px;
				color: #ffffff;
				font-weight: 500;
				font-size: 12px;
			}
		}
	}
	.modal-slide-content {
		display: flex !important;
		padding: 0 35px;
		padding-inline-end: 65px;
		margin-bottom: 44px;
		&:lang(ar) {
			text-align: right;
		}
		@media (max-width: 768px) {
			margin-bottom: 0;
			padding: 0 5px;
			padding-inline-end: 25px;
		}
		.slide-content--title {
			font-size: 32px;
			font-weight: 500;
			line-height: 150%;
			color: #ffffff;
		}
		.slide-content--desc {
			font-size: 18px;
			font-weight: 500;
			line-height: 150%;
			color: #ffffff;
			margin-bottom: 34px;
		}
		.slide-content--hinte {
			font-size: 14px;
			color: #ffffff;
			margin-bottom: 26px;
			line-height: 150%;
			margin-top: 36px;
			word-break: break-word;
		}
		.slide-content--hinte-wraning {
			font-weight: 500;
			font-size: 14px;
			color: #ff9d00;
		}
		.slide-content--article {
			display: flex;
			flex-direction: column;
			.company-title {
				font-size: 16px;
				font-weight: 600;
				color: #ffffff;
			}
		}
	}

	// .slide-content--img {
	// 	width: 50%;
	// 	position: relative;
	// 	height: 170px;
	// 	margin-bottom: 24px;
	// }
	.ant-statistic-content-value {
		font-weight: 600;
		font-size: 40px;
		color: #ffffff;
		margin-bottom: 30px;
	}
	.count-down-modal-footer {
		margin-top: 30px;
	}
	.slick-slider {
		// position: static;
		.slick-dots {
			bottom: -55px;
			right: 50px;
			width: fit-content;
			left: auto;
			&:lang(ar) {
				left: 50px;
				right: auto;
			}
			> li {
				width: 8px;
				height: 8px;
				button {
					width: 100%;
					height: 100%;
					border-radius: 50%;
				}
				&.slick-active {
					width: 12px;
					height: 12px;
				}
			}
		}
	}
	.count-down-slider-wrapper {
		display: flex;
		.count-down-slider-content {
			width: 50%;
			@media (max-width: 768px) {
				width: 100%;
			}
		}
	}
	.slick-next {
		inset-inline-end: 20px;
	}
	.slick-prev {
		inset-inline-start: 0;
	}
	.slick-next,
	.slick-prev {
		width: fit-content;
		height: fit-content;
		z-index: 22;
		.anticon {
			font-size: 26px;
			color: #fff;
		}
		@media (max-width: 768px) {
			display: none !important;
		}
	}
	.count-down-slider-imgs {
		width: 50%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		border-radius: 12px;
		&:lang(ar) {
			right: auto;
			left: 0;
		}
		@media (max-width: 768px) {
			display: none;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 0 12px 12px 0;
			opacity: 0;
			transition: all ease-in-out 0.6s;
			&.active {
				transition: all ease-in-out 0.6s;
				opacity: 1;
			}
			&:lang(ar) {
				border-radius: 12px 0 0 12px;
			}
		}
	}
}
.text-ltr {
	&:lang(ar) {
		direction: ltr;
	}
}
.text-rtl {
	&:lang(ar) {
		direction: rtl;
	}
}
.flex {
	display: flex;
	gap: 5px;
	align-items: center;
}
.ql-toolbar {
	direction: ltr;
}
.reverse-title {
	.col-btn {
		position: absolute;
		right: 40px;
		top: 30px;
		&:lang(ar) {
			left: 40px;
			right: auto;
			// position: "absolute", right: "30px", top: "30px"
			flex-direction: row-reverse;
		}
	}
}

.notify-success {
	background-color: #e9ffda;
	padding: 16px;
	.desc-content-holder {
		display: flex;
		.ant-image-img {
			border-radius: 8px;
			object-fit: cover;
		}
	}
	.desc-content-info {
		margin-inline-start: 14px;
		flex: 1;
		.info-row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.item-count {
				display: flex;
				align-items: center;
				gap: 5px;
				font-size: 18px;
				font-weight: 700;
				color: #343434;
				span {
					font-size: 14px;
					font-weight: 400;
					color: #595959;
				}
			}
		}
		.item-description__text {
			font-size: 14px;
			font-weight: 400;
			color: #343434;
			margin-top: 6px;
		}
	}
}

.menu-items-wrapper {
	.ant-dropdown-menu {
		max-height: 50vh;
		overflow: auto;
		padding-top: 0;
		padding-bottom: 0;
		.ant-dropdown-menu-item {
			&:hover {
				background-color: #fff;
			}
		}
	}

	.menu-header-wrapper {
		height: 60px;
		position: sticky;
		z-index: 2;
		top: 0;
		background-color: #fff;
		border-bottom: 1px solid #d6d9d3;
		border-radius: 0;
		margin-bottom: 10px;
		.menu-header-content {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.menu-header--title {
				font-size: 18px;
				font-weight: 500;
				color: #343434;
			}
			.menu-header--desc {
				font-size: 14px;
				color: #343434;
				display: flex;
				align-items: center;
				gap: 14px;
				strong {
					font-weight: 700;
					font-size: 16px;
				}
			}
		}
	}
	.menu-footer-wrapper {
		position: sticky !important;
		bottom: 0;
		background-color: #fff;
		z-index: 2;
		padding-top: 6px !important;
		padding-bottom: 14px !important;
		.menu-footer-content {
			display: flex;
			flex-direction: column;
			gap: 14px;
		}
		.menu-footer-summary {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.menu-footer--total {
				font-size: 14px;
				font-weight: 700;
				color: #343434;
			}
			.menu-footer--price {
				display: flex;
				align-items: center;
				gap: 5px;
				font-size: 22px;
				font-weight: 600;
				color: #77b84d;
				span {
					font-size: 12px;
					font-weight: 500;
					color: #595959;
				}
			}
		}
	}
	&.checkout-menu-items-wrapper {
		background: #ffffff;
		box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		.ant-menu-item {
			height: auto;
			margin-top: 0;
			margin-bottom: 10px;
			.menu-header-content {
				min-height: 60px;
			}
			&.menu-footer-wrapper {
				margin-bottom: 0;
			}
			&:hover {
				background-color: #fff !important;
			}
		}
	}
}
.icon-text {
	display: flex;
	align-items: center;
	gap: 4px;
}
strong {
	font-weight: 700;
}

.avatar-round-holder {
	text-align: center;
	.ant-upload-wrapper {
		width: 160px;
		height: 160px;
		border-radius: 50%;
		img {
			// padding: 5px;
		}
	}
}

.action-btns-row {
	display: flex;
	align-items: center;
	gap: 16px;
}
