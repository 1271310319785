.FooterContainer {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    width: 100%;
    padding: 0px 20px;
    .FooterSubContainer {
        min-height: 400px;
        max-width: 1426px;
        margin: auto;
        .Footer_Upper {
            min-height: 150px;
            @include flex(center, space-between);
            row-gap: 10px;
            border-bottom: 1px solid #dddddd;
            .Footer_Upper_Brand {
                cursor: pointer;
                width: 180px;
                height: 40px;
                position: relative;
            }
            .Footer_Upper_Download {
                @include flex(center, flex-start);
                column-gap: 12px;
                padding-top: 20px;
                position: relative;
                .Footer_Upper_Download_Title {
                    position: absolute;
                    bottom: 55px;
                    @include font(18px, 500, $vibrarnt-black);
                }
                .Footer_Upper_Download_Google {
                    position: relative;
                    width: 144px;
                    height: 50px;
                    .Footer_Upper_Download_Google_Image {
                        width: 144px !important;
                        height: 50px !important;
                    }
                }
                .Footer_Upper_Download_App {
                    position: relative;
                    width: 144px;
                    height: 50px;
                    .Footer_Upper_Download_App_Image {
                        width: 144px !important;
                        height: 50px !important;
                    }
                }
            }
            .Footer_Upper_Subscribe {
                .form-element-footer {
                    position: relative;
                    .subscribe-btn {
                        position: absolute;
                        height: 56px;
                        width: 100px;
                        top: 3px;
                        right: 3px;
                        font-size: 10px;
                        display: inline-block;
                    }
                    .input_class {
                        position: relative;
                        width: 415px;
                        height: 62px;
                        input.k-input {
                            height: 62px;
                            border: 1px solid $lightt-grey;
                            border-radius: 12px;
                            @include font(18px, 500, $lightt-grey);
                            padding: 0px 30px;
                            @media (max-width: 425px) {
                                padding: 0px 20px;
                            }
                            &:focus {
                                border: 1px solid $black-color;
                                box-shadow: unset;
                                color: $black-color;
                            }
                        }
                        .translate_btn_active {
                            position: absolute;
                            right: 5px;
                            top: 50%;
                            height: 47px;
                            width: 100px;
                            border-radius: 8px;
                            @include font(12px, 600, $white_color);
                            background: $primary_color;
                        }
                        .translate_btn_inactive {
                            position: absolute;
                            right: 5px;
                            top: 50%;
                            height: 47px;
                            width: 100px;
                            border-radius: 8px;
                            @include font(12px, 600, $white_color);
                            background: #aaaaaa;
                        }
                    }
                }
            }
        }
        .Footer_Middle {
            min-height: 150px;
            @include flex(flex-start, space-between);
            border-bottom: 1px solid #dddddd;
            padding: 30px 0px;
            row-gap: 10px;
            .NOTHidden {
                display: none;
            }
            .Hidden {
                display: block;
            }
            .Footer_Middle_Left {
                .Footer_Middle_Left_Title {
                    @include font(16px, bold, $vibrarnt-black);
                }
                & > p {
                    @include font(16px, 500, $grey);
                }
            }
            .Footer_Middle_Center {
                width: 387px;
                .Footer_Middle_Center_Title {
                    @include font(16px, bold, $vibrarnt-black);
                }
                & > p {
                    @include font(16px, 500, $grey);
                    span {
                        @include font(16px, 500, $black-color);
                        text-decoration: underline;
                    }
                }
            }
            .Footer_Middle_Right {
                width: 247px;
                .Footer_Middle_Right_Title {
                    @include font(16px, bold, $vibrarnt-black);
                }
                & > p {
                    @include font(12px, 500, $grey);
                }
            }
        }
        .Footer_Down {
            min-height: 90px;
            @include flex(center, space-between);
            row-gap: 10px;
            .Footer_Down_Right {
                column-gap: 14px;
                @include flex(center, center);
                cursor: pointer;
                & > svg {
                    width: 23px;
                    height: 23px;
                }
            }
            .Footer_Down_Left {
                column-gap: 80px;
                row-gap: 10px;
                @include flex(center, center);
                .Footer_Down_Left_First {
                    @include font(16px, 500, $vibrarnt-black);
                }
                .Footer_Down_Left_Second {
                    @include flex(center, center);
                    column-gap: 30px;
                    row-gap: 10px;
                    @include font(16px, 500, $vibrarnt-black);
                    & > span {
                        cursor: pointer;
                    }
                    & > p {
                        @include font(18px, 500, $vibrarnt-black);
                        height: 36px;
                        margin: 0px;
                    }
                }
            }
        }
    }
}
@media (min-width: 1440px) and (max-width: 1920px), (max-width: 1440px), (max-height: 768px) {
    .FooterContainer {
        .FooterSubContainer {
            max-width: 1150px;
            .Footer_Upper {
                .Footer_Upper_Brand {
                    width: 146px;
                    height: 33px;
                }
                .Footer_Upper_Download {
                    column-gap: 12px;
                    padding-top: 20px;
                    .Footer_Upper_Download_Title {
                        bottom: 55px;
                        @include font(14px, 500, $vibrarnt-black);
                    }
                    .Footer_Upper_Download_Google {
                        width: 116px;
                        height: 40px;
                        .Footer_Upper_Download_Google_Image {
                            width: 116px !important;
                            height: 40px !important;
                        }
                    }
                    .Footer_Upper_Download_App {
                        width: 116px;
                        height: 40px;
                        .Footer_Upper_Download_App_Image {
                            width: 116px !important;
                            height: 40px !important;
                        }
                    }
                }
                .Footer_Upper_Subscribe {
                    .form-element-footer {
                        .subscribe-btn {
                            position: absolute;
                            height: 45px;
                            width: 90px;
                            top: 3px;
                            right: 3px;
                            font-size: 10px;
                            display: inline-block;
                        }
                        .input_class {
                            width: 336px;
                            height: 51px;
                            input.k-input {
                                height: 51px;
                                border-radius: 8px;
                                @include font(14px, 500, $lightt-grey);
                            }
                            .translate_btn_active {
                                height: 38px;
                                width: 81px;
                                @include font(11px, 600, $white_color);
                            }
                            .translate_btn_inactive {
                                height: 38px;
                                width: 81px;
                                @include font(11px, 600, $white_color);
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 1280px), (max-height: 800px) {
    .FooterContainer {
        .FooterSubContainer {
            max-width: 998px;
            .Footer_Upper {
                .Footer_Upper_Brand {
                    width: 126px;
                    height: 29px;
                }
                .Footer_Upper_Download {
                    column-gap: 8px;
                    padding-top: 45px;
                    .Footer_Upper_Download_Title {
                        bottom: 35px;
                        height: 25px;
                        @include font(12px, 500, $vibrarnt-black);
                    }
                    .Footer_Upper_Download_Google {
                        width: 100px;
                        height: 35px;
                        .Footer_Upper_Download_Google_Image {
                            width: 100px !important;
                            height: 35px !important;
                        }
                    }
                    .Footer_Upper_Download_App {
                        width: 100px;
                        height: 35px;
                        .Footer_Upper_Download_App_Image {
                            width: 100px !important;
                            height: 35px !important;
                        }
                    }
                }
                .Footer_Upper_Subscribe {
                    .form-element-footer {
                        .subscribe-btn {
                            position: absolute;
                            height: 35px;
                            width: 75px;
                            top: 4px;
                            right: 4px;
                            font-size: 10px;
                            display: inline-block;
                        }
                        .input_class {
                            width: 290px;
                            height: 43px;
                            input.k-input {
                                height: 43px;
                                @include font(12px, 500, $lightt-grey);
                            }
                            .translate_btn_active {
                                right: 5px;
                                top: 50%;
                                height: 33px;
                                width: 70px;
                                @include font(8px, 600, $white_color);
                            }
                            .translate_btn_inactive {
                                right: 5px;
                                top: 50%;
                                height: 33px;
                                width: 70px;
                                @include font(8px, 600, $white_color);
                            }
                        }
                    }
                }
            }
            .Footer_Middle {
                min-height: 150px;
                padding: 30px 0px;
                .Footer_Middle_Left {
                    .Footer_Middle_Left_Title {
                        @include font(12px, bold, $vibrarnt-black);
                    }
                    & > p {
                        @include font(11px, 500, $grey);
                    }
                }
                .Footer_Middle_Center {
                    width: 186px;
                    .Footer_Middle_Center_Title {
                        @include font(12px, bold, $vibrarnt-black);
                    }
                    & > p {
                        @include font(11px, 500, $grey);
                        span {
                            @include font(11px, 500, $black-color);
                        }
                    }
                }
                .Footer_Middle_Right {
                    width: 173px;
                    .Footer_Middle_Right_Title {
                        @include font(12px, bold, $vibrarnt-black);
                    }
                    & > p {
                        @include font(11px, 500, $grey);
                    }
                }
            }
            .Footer_Down {
                min-height: 90px;
                row-gap: 10px;
                .Footer_Down_Right {
                    column-gap: 14px;
                    & > svg {
                        width: 16px;
                        height: 16px;
                    }
                }
                .Footer_Down_Left {
                    column-gap: 49px;
                    row-gap: 10px;
                    .Footer_Down_Left_First {
                        @include font(12px, 500, $vibrarnt-black);
                    }
                    .Footer_Down_Left_Second {
                        column-gap: 39px;
                        row-gap: 10px;
                        @include font(12px, 500, $vibrarnt-black);
                        & > p {
                            @include font(18px, 500, $vibrarnt-black);
                            height: 36px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 700px) {
    .FooterContainer {
        background: #f8f8f8;
        .FooterSubContainer {
            .Footer_Upper {
                flex-direction: column;
                row-gap: 22px;
                border-bottom: none;
                .Footer_Upper_Brand {
                    display: none;
                }
                .Footer_Upper_Download {
                    .Footer_Upper_Download_Title {
                        left: 12%;
                        right: 12%;
                    }
                }
            }
            .Footer_Middle {
                .NOTHidden {
                    display: block;
                }
                .Hidden {
                    display: none !important;
                }
                min-height: 150px;
                text-align: center;
                flex-direction: column;
                @include flex(center, space-between);
                border-bottom: none;
                padding: 30px 0px;
                row-gap: 10px;
                .Footer_Middle_Left,
                .NOTHidden {
                    width: 100%;
                    column-gap: 30px;
                    @include flex(center, center);
                    .Footer_Middle_Left_Title {
                        @include font(12px, bold, $vibrarnt-black);
                        text-decoration: underline;
                    }
                    & > p {
                        display: none;
                    }
                    margin-bottom: 14px;
                }
                .Footer_Middle_Center {
                    width: 100%;
                }
                .Footer_Middle_Right {
                    width: 173px;
                    .Footer_Middle_Right_Title {
                        @include font(12px, 500, #696969);
                        text-decoration: none;
                    }
                    & > p {
                        display: none;
                    }
                }
            }
            .Footer_Down {
                @include flex(center, center);
                .Footer_Down_Right {
                    column-gap: 17px;
                    & > svg {
                        opacity: 0.4;
                    }
                    span:hover {
                        opacity: 1;
                    }
                }
                .Footer_Down_Left {
                    display: none;
                }
            }
        }
    }
}
