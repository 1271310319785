html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: middle;
}

button:focus {
	outline: 0 !important;
}
* {
	box-sizing: border-box;
	&::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}
	&::-webkit-scrollbar-track {
		background: #f2f2f2;
		width: 4px;
		height: 4px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #5d5d5d;
		width: 4px;
		height: 4px;
	}
}

body {
	font-family: "Poppins", sans-serif;
	margin: 0px;
	width: 100%;
	background: #fff !important;
	&:lang(ar) {
		font-family: "Vazirmatn", sans-serif !important;
	}
}

html {
	&:lang(ar) {
		direction: rtl;
		.ant-message {
			right: 50% !important;
			left: auto !important;
			transform: translateX(50%) !important;
		}
	}
}
.scroll-container {
	overflow: scroll;
}

.scroll-container {
	scroll-behavior: smooth;
}
@media (prefers-reduced-motion) {
	html,
	.scroll-container {
		scroll-behavior: auto;
	}
}
.container,
.container-sm {
	max-width: 100%;
	margin: auto;
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;

	@media (max-width: 400px) {
		// padding-left: 5px;
		// padding-right: 5px;
	}
}

@media (min-width: 1200px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl {
		max-width: 1200px;
	}
}
@media (min-width: 1440px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl {
		// max-width: 1330px;
		// max-width: 1700px;
		max-width: 1200px;
	}
}
