.Package_Description--label {
	font-weight: 500;
	color: #171717;
	& > b {
		font-size: 14px;
		font-weight: 400;
		color: #ed6d00;
	}
}

.geographic_markets_container {
	.geographic_markets {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 10px;
		margin-top: 20px;
		margin-bottom: 16px;
		& > span {
			font-size: 16px;
			font-weight: 500;
			color: #171717;
		}
	}
	.geographic_switch {
		display: flex;
		gap: 10px;
		font-size: 16px;
		font-weight: 500;
		color: #656565;
	}
}

.ant-tree-list-holder-inner {
	// flex-direction: row !important;
	// flex-wrap: wrap;
	gap: 10px;
	transition: none !important;
	animation: none !important;
	display: grid !important;
	grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
	.ant-tree-switcher {
		line-height: 48px;
	}
	> .ant-tree-treenode {
		// min-width: 127px;
		height: 48px;
		display: flex;
		align-items: center;
		// gap: 5px;
		padding: 0px 4px;
		background-color: #fff;
		border: 1px solid #a7a7a7;
		border-radius: 8px;
		font-weight: 400;
		transition: none !important;
		animation: none !important;
		.ant-tree-checkbox-inner {
			border-radius: 4px;
		}
		.ant-tree-node-content-wrapper {
			background-color: transparent !important;
		}
		.ant-tree-title {
			white-space: nowrap;
		}
		&.ant-tree-treenode-disabled {
			border-color: rgba(0, 0, 0, 0.25);
			.ant-tree-switcher {
				pointer-events: none;
				color: rgba(0, 0, 0, 0.25);
			}
			&.ant-tree-treenode-checkbox-checked {
				background-color: #fff;
			}
		}
	}
	.child-holder {
		// height: 40px;
		opacity: 0.9;
		border-color: #5143f8;
		.ant-tree-indent,
		.ant-tree-switcher {
			display: none;
		}
	}
	.ant-tree-treenode-checkbox-checked {
		background-color: #542ef917;
		color: #542ef9;
		border-color: #542ef9 !important;
	}
	@media only screen and (max-width: 992px) {
		grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	}
}

.Item_Form {
	& > span {
		@include font(28px, 500, $vibrarnt-black);
		margin: 30px auto 27px;
		display: block;
		width: 100%;
		@include flex(center, flex-start);
		gap: 33px;
		& > button {
			cursor: pointer;
			// background: #ff422f 0% 0% no-repeat padding-box;
			border-radius: 8px;
			height: 39px;
			padding: 0px 12px;
			// @include font(12px, 600, $white_color);
			font-size: 12px;
			color: #fff !important;
			font-weight: 600;
		}
	}
	& > .Item_Form_Container {
		max-width: 768px;
		background: #f0f0f0;
		border-radius: 8px;
		padding: 20px 21px;
		margin-bottom: 20px;

		@media only screen and (max-width: 992px) {
			padding: 10px 12px;
		}
	}
	.Item_SuppliersWrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;

		.Item_Supplier {
			height: 40px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 16px;
			color: #000;
			background-color: #ffffff;
			padding: 8px 11px;
			border: 1px solid #cfd8e3;
			border-radius: 8px;
			.Item_Supplier_Icon {
				color: red;
				cursor: pointer;
			}
		}
	}
}
.Item_Supplier_Modal {
	.Item_Supplier_Modal_Title {
		font-weight: 500;
		font-size: 25px;
		margin-bottom: 20px;
	}
	.Item_Supplier_Modal_CheckboxWrapper {
		flex-direction: column !important;
		flex-wrap: nowrap !important;
		margin-top: 10px !important;
		margin-bottom: 20px !important;
		height: 200px;
		overflow: auto;

		.ant-checkbox-wrapper {
			border: 1px solid #cfd8e3;
			margin-left: 0px !important;
			border-radius: 8px;
			padding: 10px 15px;
		}
	}
	.Item_Supplier_ActionWrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		button {
			width: auto;
			height: 40px !important;
		}
	}
}
@mixin packageDetails {
	.packageDetailsContainer {
		.packageDetails_Form {
			max-width: 768px;
			margin-top: 30px;
			// border-bottom: 1px solid #d0d0d0;
			.input-holder {
				margin-bottom: 10px;
			}
			.section {
				margin-bottom: 1.5rem;
			}
		}
	}
}

.avatar-uploader {
	.ant-image {
		width: 100%;
		height: 100%;
	}
	.ant-upload {
		width: 100% !important;
		height: 125px !important;
	}
}

.avatar-uploader-circle {
	.ant-upload {
		width: 132px !important;
		height: 132px !important;
		border-radius: 50% !important;
		margin: auto !important;
		display: contents;
		// overflow: hidden;
	}
}
.photoID-uploader {
	.ant-upload {
		width: 100% !important;
		height: 125px !important;
		max-height: 100% !important;
	}
}
