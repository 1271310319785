@mixin PlanTrip() {
    .PlanTripContainer {
        max-width: 1435px;
        min-height: 500px;
        margin: auto;
        padding: 0px 20px;
        background: $white_color;
        @include flex(center, center);
        .PlanTrip_Box {
            min-height: 304px;
            width: 100%;
            background: #542ef9;
            border-radius: 25px;
            box-sizing: border-box;
            row-gap: 20px;
            @include flex(center, space-between);
            .PlanTrip_Box_Left {
                padding: 27px 109px;
                .PlanTrip_Box_Title {
                    @include font(56px, bold, $white_color);
                }
                & > p {
                    @include font(14px, 500, $white_color);
                    font-size: 14px !important;
                    width: 598px;
                    height: 83px;
                    line-clamp: 5;
                    overflow: hidden;
                    -webkit-line-clamp: 4;
                }
                .PlanTrip_ButtonBox {
                    @include flex(center, flex-start);
                    column-gap: 34px;
                    & > p {
                        height: 23px;
                        text-decoration: underline;
                        opacity: 0.7;
                        @include font(16px, 500, $white_color);
                    }
                    & > button {
                        width: 207px;
                        height: 66px;
                        background: #ffd800;
                        border-radius: 34px;
                        @include font(16px, 500, #542ef9);
                    }
                }
            }
            .PlanTrip_Box_Right {
                width: 330px;
                height: 250px;
                margin: 0px 30px;
                position: relative;
                .PlanTrip_Box_Avatar {
                    position: absolute;
                    top: 0px;
                    & > svg {
                        width: 330px;
                        height: 250px;
                    }
                }
            }
        }
        @media (min-width: 1440px) and (max-width: 1920px), (max-width: 1440px), (max-height: 768px) {
            max-width: 1205px;
            min-height: 400px;
            .PlanTrip_Box {
                min-height: 246px;
                border-radius: 18px;
                .PlanTrip_Box_Left {
                    padding: 23px 98px;
                    .PlanTrip_Box_Title {
                        @include font(42px, bold, $white_color);
                    }
                    & > p {
                        @include font(12px, 500, $white_color);
                        font-size: 12px !important;
                        width: 595px;
                        height: 67px;
                    }
                    .PlanTrip_ButtonBox {
                        column-gap: 22px;
                        & > p {
                            @include font(12px, 500, $white_color);
                        }
                        & > button {
                            width: 153px;
                            height: 53px;
                            @include font(12px, 500, #542ef9);
                        }
                    }
                }
                .PlanTrip_Box_Right {
                    width: 267px;
                    .PlanTrip_Box_Avatar {
                        & > svg {
                            width: 267px;
                            height: 290px;
                        }
                    }
                }
            }
        }
        @media (max-width: 1280px), (max-height: 800px) {
            max-width: 1040px;
            @include flex(center, center);
            .PlanTrip_Box {
                min-height: 234px;
                @include flex(center, space-between);
                .PlanTrip_Box_Left {
                    padding: 26px 76px;
                    .PlanTrip_Box_Title {
                        @include font(32px, bold, $white_color);
                    }
                    & > p {
                        @include font(14px, 500, $white_color);
                        font-size: 14px !important;
                        width: 418px;
                        height: 45px;
                        margin-bottom: 10px;
                    }
                    .PlanTrip_ButtonBox {
                        column-gap: 24px;
                        & > p {
                            @include font(12px, 500, $white_color);
                        }
                        & > button {
                            width: 145px;
                            height: 46px;
                            @include font(12px, 500, #542ef9);
                        }
                    }
                }
                .PlanTrip_Box_Right {
                    width: 265px;
                    height: 250px;
                    .PlanTrip_Box_Avatar {
                        & > svg {
                            width: 265px;
                            height: 300px;
                        }
                    }
                }
            }
        }
        @media (max-width: 937px) {
            .PlanTrip_Box {
                row-gap: 0px;
                .PlanTrip_Box_Left {
                    padding: 26px 40px;
                }
                .PlanTrip_Box_Right {
                    width: 230px;
                    margin: 0px 15px;
                    .PlanTrip_Box_Avatar {
                        & > svg {
                            width: 200px;
                            height: 290px;
                        }
                    }
                }
            }
        }
        @media (max-width: 768px) {
            min-height: 300px;
            .PlanTrip_Box {
                min-height: 200px;
                .PlanTrip_Box_Left {
                    & > p {
                        width: 218px;
                        height: 44px;
                    }
                }
                .PlanTrip_Box_Right {
                    height: 200px;
                    .PlanTrip_Box_Avatar {
                        & > svg {
                            height: 250px;
                        }
                    }
                }
            }
        }
        @media (max-width: 584px) {
            min-height: 250px;
            .PlanTrip_Box {
                min-height: 160px;
                @include flex(center, center);
                .PlanTrip_Box_Left {
                    padding: 15px 20px;
                    .PlanTrip_Box_Title {
                        @include font(16px, bold, $white_color);
                    }
                    & > p {
                        width: auto;
                    }
                    .PlanTrip_ButtonBox {
                        margin-top: 10px;
                        & > p {
                            display: none;
                        }
                        & > button {
                            // width: 102px;
                            height: 34px;
                            border-radius: 17px;
                            // @include font(10px, 500, #542ef9);
                        }
                    }
                }
                .PlanTrip_Box_Right {
                    width: auto;
                    height: auto;
                    margin: 0px 10px;
                    position: relative;
                    .PlanTrip_Box_Avatar {
                        & > svg {
                            width: 170px;
                            height: 200px;
                        }
                    }
                }
            }
        }
        @media only screen and (max-width: 992px) {
            width: 100%;
        }
    }
}
