@mixin Warn() {
    .WarnContainer {
        position: absolute;
        top: 0px;
        z-index: 2;
        width: 100%;
        min-height: 55px;
        // max-width: 1920px;
        background: #fceee6;
        @include flex(center, center);
        margin: auto;
        .Warn_Details {
            width: calc(100% - 200px);
            @include flex(center, center);
            flex-wrap: unset;
            & > span {
                opacity: 0.27;
                @include font(20px, 600, #2b2f33);
                cursor: pointer;
                top: unset !important;
            }
            .Warn_Details_Text {
                width: 1436px;
                @include flex(center, center);
                column-gap: 14px;
                p {
                    @include font(12px, 500, #2b2f33);
                }
                span {
                    text-decoration: underline;
                    @include font(12px, 500, $black-color);
                    opacity: 1;
                    top: 0px;
                }
                div {
                    position: relative;
                    width: 33px;
                    height: 33px;
                    img {
                        width: 33px;
                        height: 33px;
                    }
                }
            }
        }
        @media only screen and (max-width: 992px) {
            position: static;
            .Warn_Details {
                width: 100%;
                .Warn_Details_Text {
                    p {
                        font-size: 12px !important;
                        white-space: nowrap;
                        width: 77vw;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                img {
                    width: 23px;
                    height: 23px;
                }
            }
        }
    }
}
