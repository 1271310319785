@mixin photos {
    .photosContainer {
        & > p {
            @include font(14px, 400, #ff851c);
        }
        & > .photos_Form {
            max-width: 1160px;
            margin-top: 30px;
            padding-bottom: 60px;
            & input:focus {
                border: 0px;
            }
            .ant-form {
                width: 100%;
            }
            & > .photos_Form_Buttons {
                width: 100%;
                height: 100%;
                @include flex(center, center);
                column-gap: 16px;
                row-gap: 16px;
                position: relative;
                .ant-upload-picture-card-wrapper {
                    .ant-upload-list {
                        @include flex(center, flex-start);
                        gap: 16px;
                        &::before,
                        &::after {
                            content: none;
                        }
                        .ant-upload {
                            border: 1px solid #dbdbdb;
                            margin-right: auto !important;
                            &:lang(ar) {
                                margin-right: 0 !important;
                                margin-left: auto !important;
                            }
                            .upload_field {
                                text-decoration: underline;
                                font-size: 18px;
                                font-weight: 400;
                                color: #b9b9b9;
                                & > .anticon-upload {
                                    font-size: 60px;
                                }
                            }
                        }
                        .ant-upload-list-picture-card-container,
                        .ant-upload-list-item-container,
                        .ant-upload {
                            width: 346px;
                            height: 238px;
                            background: #f0f0f0;
                            border-radius: 8px;
                            row-gap: 17px;
                            margin: 0;
                            border: 0;
                            .ant-upload-list-item {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding: 0;
                                border: 0;
                                height: 238px;
                                .ant-upload-list-item-progress {
                                    height: 3px;
                                    // background: transparent linear-gradient(236deg, #363bfa 0%, #8457f4 100%);
                                }
                                .ant-upload-list-item-thumbnail {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                                &::before {
                                    display: none;
                                }
                                .ant-upload-list-item-name {
                                    display: none !important;
                                }
                                a {
                                    @include flex(center, center);
                                    width: 100%;
                                    object-fit: cover;
                                    border-radius: 8px;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        border-radius: 8px;
                                    }

                                    .ant-upload-list-item-thumbnail {
                                        .anticon {
                                            svg {
                                                font-size: 60px !important;
                                                filter: grayscale(1);
                                                path {
                                                    &:nth-of-type(2) {
                                                        fill: #b9b9b9;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .ant-upload-list-item-actions {
                                    opacity: 1;
                                    inset: unset;
                                    inset-block-end: 0;
                                    display: flex;
                                    justify-content: flex-end;
                                    padding-inline-end: 10px;
                                    padding-bottom: 20px;
                                    button {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-evenly;
                                        width: 88px;
                                        height: 39px;
                                        background: #ff422f;
                                        border-radius: 8px;
                                        border: 0;
                                        font-weight: 600;
                                        color: #fff;
                                        span {
                                            margin: 0;
                                            svg {
                                                font-size: 20px !important;
                                            }
                                        }
                                    }
                                    * {
                                        color: #fff;
                                        opacity: 1;
                                    }
                                }
                            }
                            @media only screen and (max-width: 992px) {
                                width: 100%;
                                // height: auto;
                                &.ant-upload-select-picture-card {
                                    min-height: 200px;
                                    display: flex;
                                    > span {
                                        flex: 1;
                                    }
                                }
                            }
                        }
                        @media only screen and (max-width: 992px) {
                            display: grid;
                            grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
                        }
                    }
                }
            }
        }
    }
}
