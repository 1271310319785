.empty-state-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vh 0;
    .empty-state-content {
        padding: 3vh 0;
        text-align: center;
        h2 {
            font-size: 22px;
            font-weight: 500;
            color: #000000;
            margin: 0;
            margin-bottom: 15px;
        }
        p {
            font-size: 15px;
            color: #797979;
            margin: 0;
        }
    }
}
